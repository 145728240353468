import React from "react"
import { Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"

const SearchButton = () =>{
  const navigate = useNavigate()
  const SearchBar = () => {
    navigate("/recherche")
  }
  return (
    <Button
      onClick={SearchBar}
      type="button"
      shape="circle"
      icon={<SearchOutlined />}
    ></Button>
  )
}

export { SearchButton }