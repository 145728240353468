import React, { useState, useEffect } from "react"

import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { post } from "@/axiosConfig"
import { useSite } from "@hooks/useSite"
import { LoginForm } from "@components/LoginForm"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { useAuth } from "@hooks/useAuth"

const Login = () => {
  const [data, setData] = useState({
    email: null,
    password: null
  })

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [loading, setLoading] = useState(false)
  const { setPageTitle } = useSite()
  const { validateMessages } = useAuth()

  const onSubmit = async (values) => {
    setLoading(true)
    const { email, password } = values
    await post("/auth/login", { email, password })
      .then((res) => {
        setLoading(false)
        toast.success(t("messages.logged_in"))
        localStorage.setItem("login_token", res.data.token)
        navigate(`/${lang}/`)
      })
      .catch(() => {
        setLoading(false)
        toast.error(t("messages.login_failed"))
      })
  }

  const onDataUpdateEmail = (e) => {
    setData({
      ...data,
      email: e.target.value
    })
  }

  const onDataUpdatePassword = (e) => {
    setData({
      ...data,
      password: e.target.value
    })
  }

  const loginFormProps = {
    onSubmit,
    onDataUpdateEmail,
    onDataUpdatePassword,
    data,
    loading,
    validateMessages
  }

  useEffect(() => {
    setPageTitle("Connexion")
  }, [])

  return (
    <MainLayout>
      <AuthSection>
        <LoginForm {...loginFormProps} />
      </AuthSection>
    </MainLayout>
  )
}

export { Login }
