import React, { useState, useEffect } from "react"
import PostCard from "@/components/Post"
import Loader from "@components/loader"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setMostViewedPosts } from "@slices/mostViewedPostSlice"
import axios from "axios"

const MostViewed = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { post_title, page } = useParams()
  const { mostViewedPosts } = useSelector((state) => state)

  const fetchData = () => {
    setIsLoading(true)
    axios
      .get(`/post/fetchmostview/${post_title}`)
      .then((response) => {
        const data = response.data
          .sort((a, b) => b.views - a.views)
          .slice(0, 10)
        dispatch(setMostViewedPosts(data))
        setIsLoading(false)
      })
      .catch((error) => {
        axios.post("/error/", {
          message: `${error.message}`
        })
      })
  }

  useEffect(() => {
    if (mostViewedPosts.posts.length === 0) {
      fetchData()
    }
  }, [post_title, page])

  return (
    <div className="sidebar-panel">
      <div className="sidebar-panel-title"> Les plus lus</div>
      <div className="sidebar-panel-content">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {mostViewedPosts.posts.filter((element) => element.slug !== post_title).map((item, idx) => (
              <PostCard
                key={idx+item.slug}
                post={item}
                className="with-row"
                hoverable={true}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export { MostViewed }
