import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Logo } from "@components/Logo";
import { useTranslation } from "react-i18next"
import { Drawer } from "antd";
import { SearchButton } from "@components/SearchButton";
import { LanguageSelector } from "@components/LanguageSelector"
import LogOutButton from "../Navbar/LogOutButton";

const SideMenu = ({ onClose, open }) => {
  const { t } = useTranslation()

  return (
    <Drawer
      width="100%"
      title="Menu"
      placement="right"
      onClose={onClose}
      open={open}
      className="sidemenu"
      footer={<div className="flex justify-between"><LogOutButton/><LanguageSelector/><SearchButton/></div>}
    >
      <div className="header">
        <Logo />
      </div>
      <ul>
        <li >
          <Link to={"/"} onClick={onClose}>
            <span>{t("menu.home")}</span>
          </Link>
        </li>
        <li>
          <Link to="/categorie/musique" onClick={onClose}>
            <span>{t("menu.music")}</span>
          </Link>
        </li>
        <li>
          <Link to="/categorie/people" onClick={onClose}>
            <span>{t("menu.people")}</span>
          </Link>
        </li>
        <li>
          <Link to="/categorie/cinema" onClick={onClose}>
            <span>{t("menu.cinema")}</span>
          </Link>
        </li>
        <li>
          <Link to="/categorie/video" onClick={onClose}>
            <span>{t("menu.videos")}</span>
          </Link>
        </li>
        <li>
          <Link to="/categorie/artistes" onClick={onClose}>
            <span>{t("menu.artists")}</span>
          </Link>
        </li>
      </ul>
    </Drawer>

  )
};

SideMenu.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
  
};
SideMenu.defaultProps = {
  onClose: null,
};

export { SideMenu };