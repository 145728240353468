import React, { useEffect, useState } from "react"
import { Button, Col, Row, Avatar } from "antd"
import { Link, useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"

import { StepsComponent } from "./steps"
import { checkCookies, deleteCookiesWithPath } from "@/utils/functions"
import { post } from "@/axiosConfig"
import { useDeleteFile } from "@/useFiles"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import "./contributor.sass"

const ContributorSummary = () => {
  const [phonenumber, setPhonenumber] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [avatarUrl, setAvatarUrl] = useState("")
  const [password, setPassword] = useState("")
  const [bio, setBio] = useState("")
  const [birthday, setBirthbirthday] = useState("")
  const [address, setAddress] = useState("")
  const [residenceCountry, setResidenceCountry] = useState("")
  const [nationality, setNationality] = useState("")
  const [idImg, setIdImg] = useState("")
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    const bioUrlFromCookie = Cookies.get("bio")
    const avatarUrlFromCookie = Cookies.get("avatar")
    const passwordFromCookie = Cookies.get("password")
    const emailFromCookie = Cookies.get("email")
    const birthdayFromCookie = Cookies.get("birthday")
    const phonenumberFromCookie = Cookies.get("phonenumber")
    const addressFromCookie = Cookies.get("address")
    const residenceCountryFromCookie = Cookies.get("residenceCountry")
    const nationalityFromCookie = Cookies.get("nationality")
    const lastnameFromCookie = Cookies.get("lastname")
    const firstnameFromCookie = Cookies.get("firstname")
    const idImgFromCookie = Cookies.get("idImg")

    if (phonenumberFromCookie) {
      setPhonenumber(phonenumberFromCookie)
    }

    if (firstnameFromCookie) {
      setFirstname(firstnameFromCookie)
    }

    if (lastnameFromCookie) {
      setLastname(lastnameFromCookie)
    }

    if (emailFromCookie) {
      setEmail(emailFromCookie)
    }

    if (avatarUrlFromCookie) {
      setAvatarUrl(avatarUrlFromCookie)
    }
    if (passwordFromCookie) {
      setPassword(passwordFromCookie)
    }
    if (bioUrlFromCookie) {
      setBio(bioUrlFromCookie)
    }
    if (birthdayFromCookie) {
      setBirthbirthday(birthdayFromCookie)
    }
    if (addressFromCookie) {
      setAddress(addressFromCookie)
    }
    if (residenceCountryFromCookie) {
      setResidenceCountry(residenceCountryFromCookie)
    }
    if (nationalityFromCookie) {
      setNationality(nationalityFromCookie)
    }
    if (idImgFromCookie) {
      setIdImg(idImgFromCookie)
    }
  }, [])

  useEffect(() => {
    Cookies.set("step", "5", { expires: 7, path: "" })
    const requiredKeys = [
      "firstname",
      "lastname",
      "phonenumber",
      "email",
      "avatar",
      "password"
    ]

    if (!checkCookies(requiredKeys)) {
      navigate("/contributor/step-4")
    }
  }, [navigate])

  const onFinish = () => {
    setLoading(true)
    const formData = {
      firstname,
      lastname,
      contact: parseInt(phonenumber.slice(1)),
      email,
      password,
      bio,
      birthday,
      nationality,
      address,
      id_img: idImg,
      residence_country: residenceCountry,
      avatar: avatarUrl,
      role: "contributor"
    }

    post("/auth/contributor", formData)
      .then((res) => {
        if (res.status === 201) {
          setLoading(false)
          deleteCookiesWithPath(
            [
              "firstname",
              "lastname",
              "phonenumber",
              "email",
              "avatar",
              "password",
              "picture",
              "step",
              "bio",
              "emailCode",
              "address",
              "residenceCountry",
              "nationality",
              "birthday",
              "idPicture",
              "idImg"
            ],
            "/contributor"
          )
          toast.success("A message will be send to your email once validated!")
          navigate("/login")
        }
      })
      .catch((error) => {
        toast.error(t("messages.email_already_used"))
        console.error(error)
        setLoading(false)
        useDeleteFile(avatarUrl, null)
      })
  }

  return (
    <MainLayout>
      <AuthSection>
        <StepsComponent currentStep={5} loading={loading} />
        <div style={{ textAlign: "center", maxWidth: "600" }}>
          <Avatar
            src={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/${avatarUrl}`}
            size={100}
            style={{
              margin: "0 auto",
              marginTop: "20px",
              marginBottom: "20px"
            }}
          />
          <div
            style={{
              maxWidth: "400px",
              margin: "0px auto",
              height: "auto"
            }}
          >
            <Row>
              <Col span={24}>
                <div className="contributor-summarry">
                  <span>Nom:</span> <span>{lastname}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Prénom:</span> <span>{firstname}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Email:</span> <span>{email}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Numéro de téléphone:</span> <span>{phonenumber}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Bio:</span> <span>{bio}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Date de naissance:</span> <span>{birthday}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Address:</span> <span>{address}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Pays de résidence:</span>{" "}
                  <span>{residenceCountry}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Nationalité:</span> <span>{nationality}</span>
                </div>
                <br />
                <div className="contributor-summarry">
                  <span>Carte identité:</span>
                  <Avatar
                    shape="square"
                    src={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/${idImg}`}
                    size={150}
                    style={{
                      margin: "0 auto",
                      marginTop: "20px",
                      marginBottom: "20px"
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="contributor-summary-footer">
            <div style={{ marginTop: "20px" }}>
              <Link to="/contributor/step-4">Retour</Link>
            </div>
            <Button
              type="primary"
              style={{ marginTop: "20px" }}
              onClick={() => onFinish()}
              loading={loading}
              disabled={loading}
            >
              Valider
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { ContributorSummary }
