import React from "react"
import PostListSkeleton from "../PostListSkeleton"
import PropTypes from "prop-types"

const PostListSkeletons = ({ count }) => {
  return (
    <div className="post-list-skeleton">
      {Array.from({ length: count }).map((_, index) => (
        <PostListSkeleton key={index} />
      ))}
    </div>
  )
}

PostListSkeletons.propTypes = {
  count: PropTypes.number,
}
  
PostListSkeletons.defaultProps = {
  count: 1,
}

export default PostListSkeletons
