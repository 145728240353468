import React from "react"
import { Provider } from "react-redux"
import { store } from "./slices"
import { LanguageRoutes } from "./Router"
const App = function () {
  return (
    <Provider store={store}>
      <LanguageRoutes />
    </Provider>
  )
}
export default App
