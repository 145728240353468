import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import placeholder from '@assets/images/placeholder.png';
import './imageWL.sass';
import { ImageViewer } from "./ImageViewer";


const PlaceHolder = ()=> (
  <ImageViewer url={placeholder} />
);





const ImageWL = ({ src, large, hoverable, title, className, showOriginal  }) => {
  const [isMounted, setIsMounted] = useState(false);
  const imageContainer = useRef(null);
  const imagePreview = useRef(null);
  const imageLoaded = useRef(null);
  const imageOriginalContainter = useRef(null);
  const imageOriginalContainterBlur = useRef(null);
  const imageOriginalContainterImage = useRef(null);

  const loadImage = () => {
    var imgLarge = new Image();
    imgLarge.src = large;
    imgLarge.title = title ? title : ''
    imgLarge.alt = title ? title : ''
    imgLarge.onload = function () {
      if (imageLoaded.current){
        imageLoaded.current.innerHTML = '';
        imageLoaded.current.appendChild(imgLarge);
        imageLoaded.current.style.backgroundImage= `url(${large})`
      }
      if (imagePreview.current){
        imagePreview.current.classList.add('hidden');
      }
      
      setTimeout(()=>{
        if (imagePreview.current && isMounted){

          imagePreview.current.remove(); 
        }
      }, 1500);
    };
  };
  const loadOriginal = () => {
    var imgLarge = new Image();
    imgLarge.src = large;
    imgLarge.title = title ? title : ''
    imgLarge.alt = title ? title : ''
    imageOriginalContainterImage.current.innerHTML =''
    imgLarge.onload = function () {
      imageOriginalContainterImage?.current?.appendChild(imgLarge);
      imageOriginalContainter.current.style.height = `${imageOriginalContainterImage.current.clientHeight}px`;
      imageOriginalContainterImage.current.style.opacity = 1
    }
  }

  useEffect(() => {
    if (showOriginal){
      loadOriginal();
    }
    if (large){
      loadImage();
    }
  }, [large, src, title]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    }
  }, []);
  


  return (
    <div className={`imageWL ${className} ${hoverable ? 'hoverable' : ''}`}>
      {!showOriginal ?
        <div className="imageWL-container" ref={imageContainer}>
          <div className="imageWL-loaded " ref={imageLoaded}/>
          <div className="imageWL-preview" ref={imagePreview} >
            { src ?
              <ImageViewer url={src} />
              :
              <PlaceHolder/>
            }
          </div>
          
        </div>
        :
        <>
          { large &&
            <div className="imageWL-orignal-container" ref={imageOriginalContainter}>
              <div className="imageWL-orignal-blur" style={{ backgroundImage: `url(${src})` }} ref={imageOriginalContainterBlur}></div>
              <div className="imageWL-orignal-image" ref={imageOriginalContainterImage}></div>
            </div>
          }
        </>
      
      }
    </div>
  )
};



ImageWL.propTypes = {
  src: PropTypes.string,
  large: PropTypes.string,
  hoverable: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  showOriginal: PropTypes.bool
};
ImageWL.defaultProps = {
  src: null,
  large: null,
  hoverable: null,
  title: null,
  className: '',
  showOriginal: false
};
  

export { ImageWL };