import React, { useCallback, useState } from "react"
import { MainLayout } from "@containers/Layouts/MainLayout"
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  OneToOneOutlined,
  PhoneOutlined
} from "@ant-design/icons"
import { Button, Form, Input, Typography } from "antd"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useAuth } from "@hooks/useAuth"


const ForgotPassword = () => {
  const { Title } = Typography
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const { validateMessages } = useAuth();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [codeSent, setCodeSent] = useState(false)
  const [phonenumber, setPhonenumber] = useState("")
  const [otp, setOtp] = useState("")
  const [user, setUser] = useState({})
  const [otpConfirmed, setOtpConfirmed] = useState(false)
  const navigate = useNavigate()

  const sendCode = useCallback((phonenumber) => {
    if (phonenumber === "") {
      console.error("Your phonenumber should be filled !")
    } else {
      axios.post("/twilio/sendcode", { phonenumber }).then((res) => {
        if (res.data && res.data.status === 201) {
          setCodeSent(true)
          setPhonenumber(phonenumber)
          toast.info("A Message have has been sent to your phone number !")
        }
      })
    }
  }, [])

  const verifyCode = useCallback(() => {
    if (otp === "") {
      console.error("Your otp should be filled !")
    } else {
      axios
        .post(`/twilio/verify`, { phonenumber, otp })
        .then((res) => {
          if (res.data && res.data.status === 200) {
            setOtpConfirmed(true)
          }
          if (res.data && res.data.status === 400) {
            console.log("Your code is incorrect !")
          }
          if (res.data && res.data.status === 404) {
            console.log("Your code has expired !")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [otp, phonenumber])

  const verifyUser = async () => {
    await axios
      .post("/auth/forgot-password", { email: email })
      .then((res) => {
        if (res.data.status === 200) {
          setUser(res.data)
          sendCode(`+${res.data.user.contact}`)
        }
        if (res.data.status === 404) {
          console.log(
            "An account with this email or phone number was not found !"
          )
        }
      })
      .catch((error) => console.error(error))
  }
  const changePassword = () => {
    if (password === confirmPassword) {
      const data = {
        token: user.resetToken,
        user_id: user.user.id,
        password
      }
      axios
        .post("/auth/reset-password", data)
        .then((res) => {
          if (res && res.status === 201) {
            navigate(`/${lang}/login`)
          }
        })
        .catch((error) => console.error(error))
    } else {
      console.log("the password did not match !")
    }
  }

  return (
    <MainLayout>
      <section className="auth-section">
        <div className="auth-form">
          <Title level={3}  className="auth-form-title">{t('password_forgotten')}</Title>
          <Form name="normal_login" className="auth-form-body" validateMessages={validateMessages}>
            <Form.Item
              name="phonenumber"
              rules={[
                {
                  required: true,
                  type: "phone"
                }
              ]}
              className="auth-form-item"
            >
              <Input
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                placeholder="enter your phone number or your email !"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            {!codeSent && (
              <Form.Item className="auth-form-item">
                <Button
                  type="primary"
                  className="auth-form-button"
                  onClick={() => verifyUser()}
                  block
                >
                Get the Code
                </Button>
              </Form.Item>
            )}
            {codeSent && !otpConfirmed ? (
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input the code you receive on your phone !"
                  }
                ]}
              >
                <Input
                  prefix={<OneToOneOutlined className="site-form-item-icon" />}
                  placeholder="Enter the code"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <p style={{ marginTop: "8px" }}>
                  <Button
                    type="primary"
                    className="auth-form-button"
                    onClick={() => verifyCode()}
                  >
                Verify
                  </Button>
                </p>
              </Form.Item>
            ) : null}

            {otpConfirmed && (
              <Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Please input your Password!" }]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    value={password}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    { required: true, message: "Please confirm your Password!" }
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="auth-form-button"
                  onClick={() => changePassword()}
                >
              Change paswword
                </Button>
              </Form.Item>
            )}
          </Form>
          <div className="auth-form-footer">
            <Button
              type="text"
              className="auth-form-button"

            >
              <Link to={`/${lang}/login`}>{t("login")}</Link>
            </Button>
          </div>

        </div>
      </section>
    </MainLayout>
  )
}

export { ForgotPassword }
