import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from "prop-types";
import { i18n } from "@/i18n";
const Date = ({ time, locale, fromNow, dateTime }) => {
  moment.locale(locale);

  return (
    <time title={moment(time).format('MM/DD/YYYY')}>
      {dateTime ? moment(time).format('LL') + "-" + moment(time).format('HH:mm') : (fromNow ? moment(time).fromNow(true) : moment(time).format('LL'))}
      
    </time>
  )
};

Date.defaultProps = {
  time: 0,
  locale: i18n.language,
  fromNow: true,
  dateTime: false,
}
Date.propTypes = {
  time: PropTypes.any,
  locale: PropTypes.string,
  fromNow: PropTypes.bool,
  dateTime: PropTypes.bool
};

export { Date };
