/* eslint-disable no-undef */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import "./style.sass"
// import { Image } from "antd";
import { ImageWL } from "@components/Image"
import { Link } from "react-router-dom"
import { Date } from "@/components/Date"
import { useTranslation } from "react-i18next"


const PostCard = ({ post, className = null, hoverable = null, imageRatio }) => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const ref = useRef(null)

  return (
    <div
      className={`postcard ${className}`}
      ref={ref}
    >
      <div className="post-image">
        <Link
          to={{ pathname: `/${lang}/${post.url}` }}
          title={post.title}
        >

          {post.image ? (
            <ImageWL
              className={imageRatio}
              title={post.title}
              src={post.image}
              large={post.image}
              hoverable={hoverable}
            />
          ) : (
            <ImageWL title={post.title} />
          )}
        </Link>
      </div>
      <div>
        <h4 className="title">
          <Link to={{ pathname: `/${lang}/${post.url}` }} title={post.title}>
            {post.title}
          </Link>
        </h4>
        <span className="postcard-date">
          <Date time={post.createdAt} fromNow={false}/>
        </span>
        <span className="post-category" style={{ borderColor: post.category.color }}>{post.category.name}</span>
      </div>

    </div>
  )
}

PostCard.propTypes = {
  post: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  imageRatio: PropTypes.string
}
PostCard.defaultProps = {
  post: null,
  className: "",
  hoverable: null,
  imageRati: ""
}

export default PostCard
