import React from "react"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { Button, Checkbox, Form, Input, Typography } from "antd"
import PropTypes from "prop-types"
import "./auth-form-style.scss"
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const LoginForm = ({
  onSubmit,
  onDataUpdateEmail,
  onDataUpdatePassword,
  data: { email, password },
  loading,
  validateMessages
}) => {
  const { Title } = Typography
  const { t } = useTranslation();
  return (
    <div className="auth-form">
      <Title level={3} className="auth-form-title">{t('login')}</Title>
      <Form
        name="normal_login"
        className="auth-form-body"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        validateMessages={validateMessages}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email' }]}
          className="auth-form-item"
        >
          <Input
            type="email"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            value={email}
            onChange={onDataUpdateEmail}
            size="middle"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true }]}
          className="auth-form-item"
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            value={password}
            onChange={onDataUpdatePassword}
            size="middle"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item className="auth-form-item">
          <Button
            type="primary"
            htmlType="submit"
            className="auth-form-button"
            loading={loading}
            disabled={loading}
            size="middle"
            danger
            block
          >
            {t('login')}
          </Button>
        </Form.Item>
        <Form.Item name="remember" className="auth-form-item">
          <Checkbox>{t('remember_me')}</Checkbox>
        </Form.Item>
      </Form>
      <div className="auth-form-footer">
        <Button type="text"  className="auth-form-forgot">
          <Link to="/forgot-password">{t('forgot_password')}</Link>   
        </Button>
        <Button
          type="primary"
          className="auth-form-button"
                
        >
          <Link to="/register">{t('register_now')}</Link>   
        </Button>
      </div>
    </div>
  )

}
LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  onDataUpdateEmail: PropTypes.func,
  onDataUpdatePassword: PropTypes.func,
  data: PropTypes.object,
  email: PropTypes.string,
  password: PropTypes.string,
  loading: PropTypes.bool,
  validateMessages: PropTypes.object
}
LoginForm.defaultProps = {
  onSubmit: null,
  onDataUpdateEmail: null,
  onDataUpdatePassword: null,
  email: null,
  password: null,
  loading: null,
  validateMessages: {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid!',
      number: '${label} is not a valid!',
    }
  }
}
export { LoginForm }