import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import "./comment-form.sass"
import { SendOutlined, LoadingOutlined } from "@ant-design/icons"
import useGetUser from "@/hooks/useGetUser"
import axios from "axios"
import { get } from "@/axiosConfig"
import { toast } from "react-toastify"
import { AvatarComponent } from "@components/Avatar"
import { Button } from "antd"

const CommentForm = ({ postId, setComments, target, onReply, cancelReply, setHasMoreData }) => {
  const { data: userInfo } = useGetUser()
  const [text, setText] = useState("")
  const [isSending, setIsSending] = useState(false)
  const textareaRef = useRef(null)
  const token = localStorage.getItem("login_token")

  const onFinish = async () => {
    setIsSending(true)
    await axios
      .post(
        `/comments/${postId}`,
        { content: text },
        { headers: { Authorization: `Token ${token}` } }
      )
      .then(() => {
        setHasMoreData(true)
        toast.info("Your comment have been sent !")
        setIsSending(false)
      })
    await get(`/comments/post/${postId}`).then((res) => {
      setComments(res.data)
    })
    setText("")
  }

  const handleInputChange = (e) => {
    setText(e.target.value)
    adjustHeight()
  }

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit" // Reset height to auto-calculate the new height
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = `${scrollHeight}px`
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (target === "reply") {
      onReply(text)
    } else {
      onFinish()
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      const isTextIsEmpty = text.trim() === ""
      if (isTextIsEmpty) {
        setText("")
        return
      }
      onSubmit(e)
    }
  }

  return (
    <>
      <div className="comment-form">
        <AvatarComponent size={"large"} picture={userInfo.avatar} />
        <div className="comment-form-wrapper">
          <form name="nest-messages" onSubmit={onSubmit}>
            <textarea
              ref={textareaRef}
              placeholder="Add a comment"
              name="comment"
              value={text}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              disabled={isSending}
            />
            <button
              type="submit"
              className="comment-form-submit-button"
              disabled={isSending}
            >
              {isSending ? <LoadingOutlined /> : <SendOutlined />}
            </button>
          </form>
        </div>
      </div>
      {target === "reply" && (
        <Button
          type="link"
          onClick={() => cancelReply(true)}
          className="comment-form-submit-button"
          style={{ marginLeft: 35, fontSize: 12 }}
          disabled={isSending}
          danger
        >
          cancel
        </Button>
      )}
    </>
  )
}

export { CommentForm }

CommentForm.propTypes = {
  postId: PropTypes.number,
  setComments: PropTypes.func,
  target: PropTypes.string,
  onReply: PropTypes.func,
  cancelReply: PropTypes.func,
  setHasMoreData: PropTypes.func,

}
