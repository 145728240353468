import React, { useEffect, useState } from "react"
import {
  ArrowRightOutlined,
  MailOutlined,
  UserOutlined
} from "@ant-design/icons"
import { Button, Col, Form, Input, Row } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
// import { post } from "@/axiosConfig"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { StepsComponent } from "./steps"
import axios from "axios"
import { toast } from "react-toastify"

const Step1Component = () => {
  const [firstname, setFirstname] = useState(Cookies.get("firstname") || "")
  const [lastname, setLastname] = useState(Cookies.get("lastname") || "")
  const [email, setEmail] = useState(Cookies.get("email") || "")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()

  const sendVerificationCode = async () => {
    await axios
      .post("/auth/email-verification", { email })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          toast.success("An email has been send to your mail. Please check!")
        } else {
          console.error("an error 500 occured on the server!")
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  useEffect(() => {
    Cookies.set("step", 1, { expires: 7, path: "" })
  }, [])

  const next = async () => {
    setLoading(true)
    Cookies.set("firstname", firstname, { expires: 7, path: "" })
    Cookies.set("lastname", lastname, { expires: 7, path: "" })
    Cookies.set("email", email, { expires: 7, path: "" })
    Cookies.set("step", 1, { expires: 7, path: "" })
    if (email !== "") {
      await sendVerificationCode()
      setLoading(false)
      navigate("/register/step-2")
    }
  }

  return (
    <MainLayout>
      <AuthSection>
        <div style={{ maxWidth: "500px", margin: "0 auto" }}>
          <StepsComponent currentStep={1} />
        </div>
        <div className="auth-form">
          <Form
            name="normal_login"
            className="auth-form-body"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="firstname"
              rules={[
                { required: true, message: "Please put your firstname!" }
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Firstname"
                value={firstname}
                defaultValue={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="lastname"
              rules={[
                { required: true, message: "Please input your Lastname!" }
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Lastname"
                value={lastname}
                defaultValue={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="email"
              className="auth-form-item"
              rules={[{ required: true, message: "Please put your email!" }]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
                value={email}
                defaultValue={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Row gutter={12} justify="space-between">
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="auth-form-button"
                  icon={<ArrowRightOutlined />}
                  disabled={loading}
                  loading={loading}
                  onClick={() => next()}
                >
                  {t("buttons.next_step")}
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="auth-form-footer">
            <Button type="text" className="auth-form-forgot">
              <Link to="/login">{t("login")}</Link>
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { Step1Component }
