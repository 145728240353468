import React from "react";
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux";


const Header = () => {
  const { title, image, description } = useSelector(state => state.header);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/${image}`}
      />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Ubnews" />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/${image}`}
      />
      {/*<!--  Non-Essential, But Required for Analytics --> */}
      <meta property="fb:app_id" content="109825489394650" />
      <meta name="twitter:site" content="@ubnewsapp" />
    </Helmet>
  );
};


export { Header };
