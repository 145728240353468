import React, { useEffect, useState } from "react"
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons"
import { Button, Col, Form, Input, Row, Space } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { StepsComponent } from "./steps"
import { checkCookies, deleteCookiesWithPath } from "@/utils/functions"
import { toast } from "react-toastify"
import { post } from "@/axiosConfig"
import axios from "axios"

const Step2Component = () => {
  const [emailCode, setEmailCode] = useState("")
  const [emailCodeVerified, setEmailCodeVerified] = useState(false)
  const [password, setPassword] = useState("")
  const [cpassword, setCpassword] = useState("")
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const { t } = useTranslation()

  useEffect(() => {
    const requiredKeys = ["firstname", "lastname", "email"]

    if (!checkCookies(requiredKeys)) {
      navigate("/register")
      toast.info(t("messages.all_fields_required"))
    }
  }, [navigate])

  useEffect(() => {
    const firstnameFromCookie = Cookies.get("firstname")
    const lastnameFromCookie = Cookies.get("lastname")
    const emailFromCookie = Cookies.get("email")

    if (firstnameFromCookie) {
      setFirstname(firstnameFromCookie)
    }

    if (lastnameFromCookie) {
      setLastname(lastnameFromCookie)
    }

    if (emailFromCookie) {
      setEmail(emailFromCookie)
    }
  }, [])

  useEffect(() => {
    if (password !== cpassword) {
      setPasswordsMatch(false)
    } else {
      setPasswordsMatch(true)
    }
  }, [password, cpassword])
  const verifyEmailCode = async () => {
    await axios
      .post("/auth/verify-email-code", { email, otp: emailCode })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          setEmailCodeVerified(true)
        } else {
          toast.error("The email code is not correct!")
        }
      })
  }

  const onFinish = () => {
    setLoading(true)
    const formData = new FormData()
    formData.append("firstname", firstname)
    formData.append("lastname", lastname)
    formData.append("email", email)
    formData.append("password", password)
    post("/auth/signup", formData)
      .then((res) => {
        setLoading(false)
        deleteCookiesWithPath(
          ["firstname", "lastname", "email", "password", "step"],
          "/"
        )
        toast.success(t("messages.signed_up_successfully"))
        localStorage.setItem("login_token", res.data.token)
        navigate("/")
      })
      .catch((error) => {
        toast.error(t("messages.email_already_used"))
        console.error(error)
        setLoading(false)
        deleteCookiesWithPath(
          ["firstname", "lastname", "email", "password", "step"],
          "/"
        )
      })
  }

  return (
    <MainLayout>
      <AuthSection>
        <div style={{ maxWidth: "500px", margin: "0 auto" }}>
          <StepsComponent currentStep={2} />
        </div>
        <div className="auth-form">
          <Form
            name="normal_login"
            className="auth-form-body"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="emailCode"
              className="auth-form-item"
              rules={[{ required: true, message: t("text.enter_code_send") }]}
            >
              <Space>
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="EmailCode"
                  value={emailCode}
                  defaultValue={emailCode}
                  type="emailCode"
                  onChange={(e) => setEmailCode(e.target.value)}
                />
                {!emailCodeVerified && (
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="auth-form-button"
                    onClick={() => verifyEmailCode()}
                    loading={loading}
                    disabled={loading}
                  >
                    {t("buttons.verify")}
                  </Button>
                )}
              </Space>
            </Form.Item>
            {emailCodeVerified && (
              <>
                <Form.Item
                  className="auth-form-item"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" }
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    value={password}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  className="auth-form-item"
                  name="cpassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input confirmation password!"
                    }
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirmation Password"
                    value={cpassword}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={(e) => setCpassword(e.target.value)}
                  />
                </Form.Item>
              </>
            )}
            {!passwordsMatch && password.length > 0 && (
              <p style={{ color: "red" }}>{t("messages.passwords_mismatch")}</p>
            )}
            <Row gutter={12} justify="space-between">
              <Col>
                <Button
                  htmlType="submit"
                  icon={<ArrowLeftOutlined />}
                  type="primary"
                  className="auth-form-button"
                  onClick={() => navigate("/register")}
                >
                  {t("buttons.prev_step")}
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="auth-form-button"
                  onClick={() => onFinish()}
                  loading={loading}
                  disabled={loading || !emailCodeVerified}
                >
                  {t("buttons.finish")}
                </Button>
              </Col>
            </Row>
          </Form>
          <div className="auth-form-footer">
            <Button type="text" className="auth-form-forgot">
              <Link to="/login">{t("login")}</Link>
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { Step2Component }
