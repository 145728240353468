import React, { useEffect, useState } from "react"
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UserOutlined,
  RedEnvelopeOutlined,
  CalendarOutlined,
  FlagOutlined,
  FlagFilled
} from "@ant-design/icons"
import { Avatar, Button, Col, Form, Input, Row } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
// import { post } from "@/axiosConfig"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { StepsComponent } from "./steps"
import { checkCookies } from "@/utils/functions"
import { useUploadFile } from "@/useFiles"
import axios from "axios"
import { toast } from "react-toastify"

const ContributorStep2 = () => {
  const [firstname, setFirstname] = useState(Cookies.get("firstname") || "")
  const [lastname, setLastname] = useState(Cookies.get("lastname") || "")
  const [email, setEmail] = useState(Cookies.get("email") || "")
  const [address, setAddress] = useState(Cookies.get("address") || "")
  const [birthday, setBirthday] = useState(Cookies.get("birthday") || "")
  const [imageUrl, setImageUrl] = useState(Cookies.get("idImg") || null)
  const [idPicture, setIdPicture] = useState(Cookies.get("idPicture") || null)
  const [imageChanged, setImageChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [nationality, setNationality] = useState(
    Cookies.get("nationality") || ""
  )
  const [residenceCountry, setResidenceCountry] = useState(
    Cookies.get("residenceCountry") || ""
  )
  const avatar = Cookies.get("idImg") || null

  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  useEffect(() => {
    Cookies.set("step", 2, { expires: 7, path: "" })
  }, [])

  useEffect(() => {
    const requiredKeys = ["phonenumber"]

    if (!checkCookies(requiredKeys)) {
      navigate(`/${lang}/contributor`)
    }
  }, [navigate])
  const sendVerificationCode = async () => {
    await axios
      .post("/auth/email-verification", { email })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          toast.success("An email has been send to your mail. Please check!")
        } else {
          console.error("an error 500 occured on the server!")
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  const next = async (image) => {
    await sendVerificationCode()
    Cookies.set("firstname", firstname, { expires: 7, path: "" })
    Cookies.set("lastname", lastname, { expires: 7, path: "" })
    Cookies.set("email", email, { expires: 7, path: "" })
    Cookies.set("address", address, { expires: 7, path: "" })
    Cookies.set("birthday", birthday, { expires: 7, path: "" })
    Cookies.set("nationality", nationality, { expires: 7, path: "" })
    Cookies.set("residenceCountry", residenceCountry, { expires: 7, path: "" })
    Cookies.set("idImg", image.key, { expires: 7, path: "" })
    Cookies.set("idPicture", idPicture, { expires: 7, path: "" })
    Cookies.set("step", 2, { expires: 7, path: "" })
    navigate(`/${lang}/contributor/step-3`)
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setImageUrl(file)
      setIdPicture(URL.createObjectURL(file))
      setImageChanged(true)
    }
  }
  const uploadPicture = () => {
    if (imageChanged) {
      setLoading(true)
      useUploadFile(imageUrl, next)
    } else {
      navigate(`/${lang}/contributor/step-3`)
    }
  }
  return (
    <MainLayout>
      <AuthSection>
        <StepsComponent currentStep={2} />
        <div className="auth-form">
          <Form
            name="normal_login"
            className="auth-form-body"
            layout="vertical"
            initialValues={{ remember: true }}
            style={{
              minWidth: 400
            }}
          >
            <Form.Item
              name="firstname"
              rules={[
                { required: true, message: "Please put your firstname!" }
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Firstname"
                value={firstname}
                defaultValue={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="lastname"
              rules={[
                { required: true, message: "Please input your Lastname!" }
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Lastname"
                value={lastname}
                defaultValue={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<RedEnvelopeOutlined className="site-form-item-icon" />}
                placeholder="Email"
                type="email"
                value={email}
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                { required: true, message: "Please input your Address!" }
              ]}
            >
              <Input.TextArea
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Address"
                value={address}
                defaultValue={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="birthday"
              rules={[
                { required: true, message: "Please input your Birthday!" }
              ]}
            >
              <Input
                prefix={<CalendarOutlined className="site-form-item-icon" />}
                placeholder="Birthday"
                value={birthday}
                defaultValue={birthday}
                type="date"
                onChange={(e) => setBirthday(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="nationality"
              rules={[
                { required: true, message: "Please input your Nationality!" }
              ]}
            >
              <Input
                prefix={<FlagOutlined className="site-form-item-icon" />}
                placeholder="Nationality"
                value={nationality}
                defaultValue={nationality}
                onChange={(e) => setNationality(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="residenceCountry"
              rules={[
                {
                  required: true,
                  message: "Please input your residence country!"
                }
              ]}
            >
              <Input
                prefix={<FlagFilled className="site-form-item-icon" />}
                placeholder="Residence Country"
                value={residenceCountry}
                defaultValue={residenceCountry}
                onChange={(e) => setResidenceCountry(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Enter your ID File" valuePropName="fileList">
              <div>
                {imageUrl && (
                  <div>
                    {avatar && !imageChanged ? (
                      <Avatar
                        shape="circle"
                        size="large"
                        src={`${process.env.REACT_APP_BACKEND_URL}/files/${avatar}`}
                      />
                    ) : (
                      <img
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                        src={idPicture}
                        alt="User's Selected Image"
                      />
                    )}
                  </div>
                )}
                <input
                  style={{ marginTop: "10px" }}
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  disabled={loading}
                />
              </div>
            </Form.Item>
            <Row gutter={12} justify="space-between">
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<ArrowLeftOutlined />}
                  className="auth-form-button"
                  onClick={() => navigate(`/${lang}/contributor`)}
                ></Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="auth-form-button"
                  icon={<ArrowRightOutlined />}
                  onClick={() => uploadPicture()}
                ></Button>
              </Col>
            </Row>
          </Form>
          <div className="auth-form-footer">
            <Button type="text" className="auth-form-forgot">
              <Link to={`/${lang}/login`}>{t("login")}</Link>
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { ContributorStep2 }
