import React from "react"
import { Skeleton } from "antd"
import Container from "@components/Container/Container"

const SinglePostSkeleton = () => (
  <>
    <Skeleton.Input block
      style={{ height: 'auto', width: '100%', aspectRatio: '4/1' }}
      active={true} size={'default'}
    />
      
    <Container>
      <Skeleton active block paragraph={{ rows: 10 }}/>
    </Container>
  </>
)
  
export { SinglePostSkeleton }