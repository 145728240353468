import React, { useEffect, useState } from "react"

import Container from "@components/Container/Container"
import { Helmet } from "react-helmet"

import { Row, Input, Col, Button } from "antd"
import { MainLayout } from "@containers/Layouts/MainLayout"
import Lottie from "react-lottie"
import animationData from "../../assets/lotties/animation_llzettrs.json"
import { setSearchValue } from "@slices/searchBarSlice"
import { useDispatch } from "react-redux"
import { LeftOutlined, SearchOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const PageNotFound = () => {
  const [inputValue, setInputValue] = useState("")
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSearch = (value) => {
    if (value && value.length > 0) {
      dispatch(setSearchValue(value))
      navigate("/recherche")
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  useEffect(() => {
    document.title = "404 Page Not found"
  }, [])

  return (
    <>
      <Helmet>
        <meta property="og:title" content={"Urbenhits"} />
        <meta property="og:type" content="page" />
        <meta
          property="og:image"
          content={`https://urbenhits.com/themes/custom/urbenhits/assets/img/02.png`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />

        {/* <!--  Non-Essential, But Recommended --> */}
        <meta
          property="og:description"
          content={"Site web culture et société"}
        />
        <meta property="og:site_name" content="Urbenhits" />
        <meta name="twitter:image:alt" content={"Urbenhits"} />
        <meta
          name="twitter:description"
          content={"Site web culture et société"}
        />
        <meta
          name="twitter:image"
          content={`https://urbenhits.com/themes/custom/urbenhits/assets/img/02.png`}
        />
        {/*<!--  Non-Essential, But Required for Analytics --> */}
        <meta property="fb:app_id" content="109825489394650" />
        <meta name="twitter:site" content="@ubnewsapp" />
      </Helmet>
      <MainLayout>
        <Container>
          {/* 
          <Title level={2} className="title" style={{ color: 'red', textAlign: 'center' }}>
            Page not found
          </Title> */}
          <Row>
            <Lottie options={defaultOptions} height={500} width={500} />
          </Row>
          <Row align="center" justify="center" gutter={[16, 16]}>
            <Col xs={16} sm={16} md={10} lg={10} xl={10}>
              <div className="search-container">
                <Input
                  className="search-input"
                  placeholder={t("search.description")}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={() => handleSearch(inputValue)}
                  min={1}
                />
                <div className="search-icon">
                  <Button
                    onClick={() => handleSearch(inputValue)}
                    type="button"
                    className="border-none"
                    icon={<SearchOutlined />}
                  ></Button>
                </div>
              </div>
              <Button type="link" href="/" icon={<LeftOutlined />}>
                {t("buttons.return_to_home")}
              </Button>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </>
  )
}

export default PageNotFound
