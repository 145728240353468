import React, { useRef, useState } from "react"
import { Modal, Form, Input } from "antd"
import PropTypes from "prop-types"
import { useUploadFile } from "@/useFiles"
import axios from "axios"
import { useTranslation } from "react-i18next"

const EditImageModal = ({ open, setOpen, userId }) => {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)
  const [imageChanged, setImageChanged] = useState(false)
  const token = localStorage.getItem("login_token")
  const { t } = useTranslation()

  const fileInputRef = useRef(null)
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFile(file)
      setPreview(URL.createObjectURL(file))
      setImageChanged(true)
    }
  }

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  }
  const handleOk = (image) => {
    setOpen(false)
    let form_data = { avatar: image.key }
    axios
      .put(`/user/${userId}`, form_data, {
        headers: { authorization: `Token ${token}` }
      })
      .then(() => {
        window.location.reload(false)
      })
  }

  const handleCancel = () => {
    setOpen(false)
  }
  const uploadPicture = () => {
    if (imageChanged) {
      setLoading(true)
      useUploadFile(selectedFile, handleOk)
    } else {
      handleCancel()
    }
  }

  return (
    <div>
      <Modal
        title={t("messages.edit_profile_image_modal")}
        open={open}
        onOk={uploadPicture}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="nest-messages"
          style={{
            maxWidth: 600
          }}
        >
          {imageChanged && (
            <div className="edit-image-preview">
              <h3>{t("text.preview")}:</h3>
              <img
                style={{ maxWidth: "200px", maxHeight: "200px" }}
                src={preview}
                alt="User's Selected Image"
              />
            </div>
          )}
          <Form.Item
            name={["user", "Image"]}
            label="Image"
            rules={[
              {
                type: "file"
              }
            ]}
          >
            <Input
              name="image"
              style={{ marginTop: "10px" }}
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={handleFileChange}
              disabled={loading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default EditImageModal

EditImageModal.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.number
}
