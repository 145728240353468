import { MainLayout } from "@/containers/Layouts/MainLayout"
import React from "react"
import Container from "../Container/Container"
import { Row } from "antd"
import { useTranslation } from "react-i18next"

const ErrorBoundaryFallback = ()=>{
  const { t } = useTranslation()
  return (
    <MainLayout>
      <Container>
        <Row>
          <div className="boundary-fallback-message">
            <h3>{t("messages.fallback_message_title")}</h3>
            <p>{t("messages.fallback_message_desc")}</p>
          </div>
        </Row>
      </Container>
    </MainLayout>
  )
}

export { ErrorBoundaryFallback }