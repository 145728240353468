import { Skeleton, Flex } from "antd"
import React from "react"

const PostListSkeleton = () => {
  return (
    <Flex style={{ marginTop: "5px", marginBottom: "20px", gap: 16 }}>
      <Skeleton.Avatar active size={"large"} shape={"square"} style={{ width: "80px", height: "80px" }}/>
      <div style={{ width: "100%" }}>
        <Skeleton.Button block active size="small" style={{ height: "10px" }}/>
        <Skeleton.Button block active size="small" style={{ height: "10px" }}/>
        <Skeleton.Button block active size="small" style={{ height: "10px" }}/>
        <Skeleton.Button block active size="small" style={{ height: "10px" }}/>
      </div>
    </Flex>
  )
}

export default PostListSkeleton
