import React, { useState } from "react"
import { Modal, Form, Input } from "antd"
import PropTypes from "prop-types"
import axios from "axios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

const ChangePaswword = ({ open, setOpen, userId, email }) => {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [currentPassword, setCurrentPassword] = useState("")
  const [confirmPassword, setconfirmPassword] = useState("")
  const token = localStorage.getItem("login_token")
  const { t } = useTranslation()

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  }
  const handleOk = () => {
    setLoading(true)
    if (password === confirmPassword) {
      if (currentPassword !== "") {
        let form_data = {
          token,
          user_id: userId,
          password,
          email,
          current_password: currentPassword
        }
        axios
          .post("/auth/reset-password", form_data, {
            headers: { Authorization: `Token ${token}` }
          })
          .then((res) => {
            if (res.data.status === 200) {
              localStorage.removeItem("login_token")
              setOpen(false)
              setLoading(false)
              toast.success(t("messages.password_changed"))
              window.location.replace("/login")
            }
            if (res.data.status === 400) {
              toast.info(t("messages.current_password_incorrect"))
              setLoading(false)
            }
          })
      } else {
        toast.error(t("messages.fill_current_password"))
      }
    } else {
      toast.info(t("messages.error_different_password"))
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div>
      <Modal
        title={t("messages.password_modal_title")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="nest-messages"
          style={{
            maxWidth: 600
          }}
        >
          <Form.Item
            name={["user", "current password"]}
            label="Current Password"
            rules={[
              {
                type: "file"
              }
            ]}
          >
            <Input
              name="currentpassword"
              style={{ marginTop: "10px" }}
              type="password"
              accept="image/*"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name={["user", "password"]}
            label={t("input.password")}
            rules={[
              {
                type: "file"
              }
            ]}
          >
            <Input
              name="password"
              style={{ marginTop: "10px" }}
              type="password"
              accept="image/*"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name={["user", "confirm password"]}
            label={t("input.confirm_password")}
            rules={[
              {
                type: "file"
              }
            ]}
          >
            <Input
              name="confirmPassword"
              style={{ marginTop: "10px" }}
              type="password"
              accept="image/*"
              value={confirmPassword}
              onChange={(e) => setconfirmPassword(e.target.value)}
              disabled={loading}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ChangePaswword

ChangePaswword.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  userId: PropTypes.number,
  email: PropTypes.string
}
