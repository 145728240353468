import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const categoryPostSlice = createSlice({
  name: 'categoryPost',
  initialState,
  reducers: {
    setCategoryPosts: (state, action) => {
      state[action.payload.name] = {
        lastPost: action.payload.lastPost,
        posts: [...action.payload.posts]
      }
    },
    addCategoryPosts: (state, action) => {
      const posts = [...state[action.payload.name].posts]
      state[action.payload.name] = {
        lastPost: action.payload.lastPost,
        posts: posts
      }
    },

  },
})

// Action creators are generated for each case reducer function
export const { addCategoryPosts, setCategoryPosts } = categoryPostSlice.actions

export default categoryPostSlice.reducer