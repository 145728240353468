import React, { useEffect } from "react"
import { connect } from "react-redux"
import HomeCategoryView from "@components/HomeCategoryView"
import { useParams } from "react-router-dom"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { useSite } from "@hooks/useSite"
import { AdComponent } from "@/components/AdComponent"

export const Category = () => {
  const { category_name } = useParams()
  const { setPageTitle } = useSite()

  useEffect(async () => {
    setPageTitle(category_name.charAt(0).toUpperCase() + category_name.slice(1))
  }, [category_name])

  return (
    <MainLayout>
      <AdComponent key={category_name} space_uuid="banner_category"/>
      <HomeCategoryView category_name={category_name || ""} />
    </MainLayout>
  )
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(Category)
