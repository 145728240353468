import React, { useState } from "react"
import PropTypes from "prop-types"

import { Navbar } from "@containers/Navbar"
import { Header } from "@components/Header"
import { AppFooter } from "@components/Footer"
import { SearchBar } from "@containers/SearchBar"
import { SideMenu } from "@containers/SideMenu"
import "@assets/style/App.sass"
const MainLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)

  const handleMenu = () => {
    setShowMenu(!showMenu)
  }
  return (
    <>
      <Header />
      <div className={`app`}>
        <div className="main-content">
          <Navbar handleMenu={handleMenu} />
          <div className="page" id="page">
            <SearchBar />
            {children}
            <AppFooter />
          </div>
        </div>
      </div>
      <SideMenu onClose={handleMenu} open={showMenu} />
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export { MainLayout }
