/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react"
import { Col, Row, Typography, Button } from "antd"
import slugify from "react-slugify"
import PropTypes from "prop-types"
import PorstCard from "@/components/Post"
import Container from "./Container/Container"
import { useDispatch, useSelector } from "react-redux"
import { setCategoryPosts, addCategoryPosts } from "@slices/categoryPostSlice"
import { SkeletonPostCard } from "./SkeletonPostCard"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import Lottie from "react-lottie"
import animationData from "../assets/lotties/animation_empty.json"
import { useTranslation } from "react-i18next"
import { get, post } from "@/axiosConfig"

export default function HomeCategoryView({ name }) {
  const [posts, setPosts] = useState([])
  const { categoryPosts } = useSelector((state) => state)
  const [lastDoc, setLastDoc] = useState(null)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { Title } = Typography
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const fetchMoreData = useCallback(() => {
    setIsLoadingMore(true)
    get("/post/fetchcategorypostdata", {
        name,
        last_post: categoryPosts[slugify(name)].lastPost.id
      })
      .then((res) => {
        if (res.data.length == 0) {
          setHasMoreData(false)
          setIsLoadingMore(false)
        } else {
          let lastPost = res.data[res.data.length - 1]
          const data = {
            name: slugify(name),
            posts: res.data,
            lastPost
          }
          dispatch(addCategoryPosts(data))
          setPosts([...posts, ...res.data])
          setLastDoc(lastPost)
          setIsLoadingMore(false)
        }
      })
  }, [lastDoc, name, categoryPosts[(slugify(name), posts)]])

  const fetchData = () => {
    setIsLoading(true)
    get("/post/fetchcategorypostdata", {
        name,
        last_post: 0
      })
      .then((res) => {
        if (res.data) {
          let lastPost = res.data[res.data.length - 1]
          const data = {
            name: slugify(name),
            posts: res.data,
            lastPost
          }
          dispatch(setCategoryPosts(data))
          setPosts([...res.data])
          setLastDoc(lastPost)
        }
      })
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [name])
  return (
    <Container>
      <Title level={1} className="page-title" style={{ color: "red" }}>
        {name}
      </Title>
      {isLoading ? (
        <Row gutter={[32, 32]}>
          <Col md={6} xs={24}>
            <SkeletonPostCard />
          </Col>
          <Col md={6} xs={24}>
            <SkeletonPostCard />
          </Col>
          <Col md={6} xs={24}>
            <SkeletonPostCard />
          </Col>
          <Col md={6} xs={24}>
            <SkeletonPostCard />
          </Col>
        </Row>
      ) : (
        <>
          <Row gutter={[32, 32]}>
            {posts.map((item, index) => (
              <Col key={index} md={6} xs={24}>
                <PorstCard post={item} imageRatio="ratio_165" />
              </Col>
            ))}
            {isLoadingMore && (
              <>
                <Col md={6} xs={24}>
                  <SkeletonPostCard />
                </Col>
                <Col md={6} xs={24}>
                  <SkeletonPostCard />
                </Col>
                <Col md={6} xs={24}>
                  <SkeletonPostCard />
                </Col>
                <Col md={6} xs={24}>
                  <SkeletonPostCard />
                </Col>
              </>
            )}
          </Row>

          {posts.length === 0 && (
            <Row>
              <Col xs={24}>
                <Lottie options={defaultOptions} width={200} height={200} />
              </Col>
              <Col xs={24}>
                <p className="text-center" style={{ textAlign: "center" }}>
                  {t("empty_list")}
                </p>
              </Col>
            </Row>
          )}
          {!isLoading &&
            categoryPosts[slugify(name)] &&
            categoryPosts[slugify(name)].lastPost && (
              <div style={{ textAlign: "center" }}>
                <Button
                  danger
                  disabled={!hasMoreData}
                  loading={isLoadingMore}
                  onClick={fetchMoreData}
                  shape="round"
                >
                  Afficher Plus
                </Button>
              </div>
            )}
        </>
      )}
    </Container>
  )
}

HomeCategoryView.propTypes = {
  name: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.instanceOf(Object))
}
HomeCategoryView.defaultProps = {
  name: null,
  articles: []
}
