import React, { useCallback, useEffect, useState } from "react"
import { PhoneOutlined, OneToOneOutlined } from "@ant-design/icons"
import { Button, Form, Input } from "antd"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { post } from "@/axiosConfig"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { StepsComponent } from "./steps"

const ContributorStep1 = () => {
  const [phonenumber, setPhonenumber] = useState(
    Cookies.get("phonenumber") || ""
  )
  const [codeSent, setCodeSent] = useState(false)
  const [otp, setOtp] = useState("")
  const [otpConfirmed, setOtpConfirmed] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    Cookies.set("step", 1, { expires: 7, path: "" })
  }, [])

  const sendCode = useCallback(async () => {
    setLoading(true)
    if (phonenumber === "") {
      toast.error(t("messages.phone_number_required"))
      setLoading(false)
    } else {
      await post("/twilio/sendcode", { phonenumber })
        .then((res) => {
          if (res.data && res.data.status === 201) {
            setCodeSent(true)
            toast.success(t("messages.message_sent"))
            setLoading(false)
          }
        })
        .catch(() => {
          setLoading(false)
          toast.error(t("messages.message_send_error"))
        })
    }
  }, [phonenumber])

  const verifyCode = useCallback(() => {
    setLoading(true)
    if (otp === "") {
      toast.error(t("messages.otp_required"))
      setLoading(false)
    } else {
      post(`/twilio/verify`, { phonenumber, otp })
        .then((res) => {
          setLoading(false)
          if (res.data && res.data.status === 200) {
            setOtpConfirmed(true)
            Cookies.set("phonenumber", phonenumber, { expires: 7, path: "" })
            navigate("/contributor/step-2")
          }
          if (res.data && res.data.status === 400) {
            toast.error(t("messages.incorrect_code"))
          }
          if (res.data && res.data.status === 404) {
            toast.log(t("messages.code_expired"))
          }
        })
        .catch(() => {
          toast.error(t("messages.error_occurred"))
        })
    }
  }, [otp, phonenumber])

  return (
    <MainLayout>
      <AuthSection>
        <StepsComponent currentStep={1} />
        <div className="auth-form">
          <Form
            name="normal_login"
            className="auth-form-body"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="phonenumber"
              rules={[
                { required: true, message: "Please input your Phonenumber!" }
              ]}
            >
              <Input
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                placeholder="+XXX-XXXXXXXX"
                value={phonenumber}
                defaultValue={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
              />
              {!codeSent && (
                <p style={{ marginTop: "8px" }}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="auth-form-button"
                    onClick={() => sendCode()}
                    loading={loading}
                    disabled={loading}
                    block
                  >
                    {t("buttons.get_the_code")}
                  </Button>
                </p>
              )}
            </Form.Item>
            {codeSent && !otpConfirmed ? (
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input the code you receive on your phone !"
                  }
                ]}
              >
                <Input
                  prefix={<OneToOneOutlined className="site-form-item-icon" />}
                  placeholder="Enter the code"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <p style={{ marginTop: "8px" }}>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="auth-form-button"
                    onClick={() => verifyCode()}
                    loading={loading}
                    disabled={loading}
                  >
                    {t("buttons.verify")}
                  </Button>
                </p>
              </Form.Item>
            ) : null}
          </Form>
          <div className="auth-form-footer">
            <Button type="text" className="auth-form-forgot">
              <Link to="/login">{t("login")}</Link>
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { ContributorStep1 }
