import { useState, useEffect } from 'react';

/**
 * Array of breakpoints based on Bootstrap's breakpoints for different screen sizes.
 * @type {Array<Object>}
 * @property {string} name - The name of the breakpoint (e.g., 'xs', 'sm', 'md', etc.).
 * @property {number} [minScreenSize] - The minimum screen width (inclusive) for this breakpoint.
 * @property {number} [maxScreenSize] - The maximum screen width (inclusive) for this breakpoint.
 */
const BreakPoints = [
  {
    name: 'xs',  // Extra small: <576px
    maxScreenSize: 575,
  },
  {
    name: 'sm',  // Small: ≥576px
    minScreenSize: 576,
    maxScreenSize: 767,
  },
  {
    name: 'md',  // Medium: ≥768px
    minScreenSize: 768,
    maxScreenSize: 991,
  },
  {
    name: 'lg',  // Large: ≥992px
    minScreenSize: 992,
    maxScreenSize: 1199,
  },
  {
    name: 'xl',  // Extra large: ≥1200px
    minScreenSize: 1200,
    maxScreenSize: 1399,
  },
  {
    name: 'xxl',  // Extra extra large: ≥1400px
    minScreenSize: 1400,
  }
];

/**
 * Custom React hook that detects the current screen size based on predefined breakpoints.
 * It listens to window resize events and updates the screen size state accordingly.
 * 
 * @returns {Object} - The hook returns an object containing the current screen size.
 * @property {string} screenSize - The current screen size (e.g., 'xs', 'sm', 'md', etc.).
 */
const useScreenBreakpoint = () => {
  const [screenSize, setScreenSize] = useState('');

  /**
   * Detects the current breakpoint based on the window's inner width.
   * It iterates through the breakpoints and finds the matching one.
   * 
   * @returns {string} - The name of the matching breakpoint.
   */
  const detectBreakpoint = () => {
    const width = window.innerWidth;
    let breakpoint = '';

    // Iterate over breakpoints to find the matching one
    for (let i = 0; i < BreakPoints.length; i++) {
      const { minScreenSize, maxScreenSize, name } = BreakPoints[i];

      if (minScreenSize && width >= minScreenSize && (!maxScreenSize || width <= maxScreenSize)) {
        breakpoint = name;
        break;
      } else if (!minScreenSize && maxScreenSize && width <= maxScreenSize) {
        breakpoint = name;
        break;
      }
    }

    return breakpoint;
  };

  useEffect(() => {
    // Initialize the screen size based on the current window width
    const breakpoint = detectBreakpoint();  
    setScreenSize(breakpoint); // Set it in state

    /**
     * Handles the window resize event and updates the screen size state if necessary.
     */
    const handleResize = () => {
      const newBreakpoint = detectBreakpoint();
      if (newBreakpoint !== screenSize) {
        setScreenSize(newBreakpoint);  // Only update state if it has changed
      }
    };

    window.addEventListener('resize', handleResize); // Add resize listener

    // Initial screen size check
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenSize]);  // Dependency array to monitor changes in screenSize

  return { screenSize };
};

export default useScreenBreakpoint;