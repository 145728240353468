/* eslint-disable no-undef */
import React, { useRef } from "react"
import PropTypes from "prop-types"
import "./style.sass"
// import { Image } from "antd";
import { ImageWL } from "@components/Image"
import { Link } from "react-router-dom"
import { useOnScreen } from "@/utils/useOnScreen"

const PostCard = ({ post, className = null, hoverable = null, imageRatio }) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)

  return (
    <div
      className={`postcard ${className} ${!isVisible ? "is-out-viewport" : ""}`}
      ref={ref}
    >
      <Link
        to={{ pathname: `/${post.url}` }}
        className="image"
        title={post.title}
      >
        {post.image ? (
          <ImageWL
            className={imageRatio}
            title={post.title}
            src={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}tr:w-60,h-39/${post.image}`}
            large={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}tr:w-300,h-195/${post.image}`}
            hoverable={hoverable}
          />
        ) : (
          <ImageWL title={post.title} />
        )}
      </Link>
      <h4 className="title">
        <Link to={{ pathname: `/${post.url}` }} title={post.title}>
          {post.title}
        </Link>
      </h4>
    </div>
  )
}

PostCard.propTypes = {
  post: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  hoverable: PropTypes.bool,
  imageRatio: PropTypes.string
}
PostCard.defaultProps = {
  post: null,
  className: "",
  hoverable: null,
  imageRati: ""
}

export default PostCard
