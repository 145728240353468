import React from "react";
import "./auth-section-style.scss"
import PropTypes from 'prop-types'

const AuthSection = ({ children }) => <section className="auth-section">{children}</section>

AuthSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthSection }