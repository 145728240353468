import React, { useState, useEffect } from "react"
import PostCard from "@/components/Post"
// import Loader from "@components/loader"
import { useParams } from "react-router-dom"
import axios from "axios"
import PostListSkeletons from "@/components/PostListSkeletons"
import { i18n } from "@/i18n"

const MostViewed = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const { post_title, page } = useParams()
  const [mostViewedPosts, setMostViewedPosts] = useState([])
  const selectedLanguage = i18n.language
  const fetchData = () => {
    const lang = selectedLanguage === "fr" ? "fr" : "en"
    setIsLoading(true)
    axios
      .get(`/post/fetchmostview/${lang}/${post_title}`)
      .then((response) => {
        if (!isMounted) {
          return
        }
        setMostViewedPosts(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        axios.post("/error/", {
          message: `${error.message}`
        })
      })
  }

  useEffect(() => {
    if (mostViewedPosts.length === 0) {
      fetchData()
    }
  }, [post_title, page, isMounted])

  useEffect(() => {
    console
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  return (
    <div className="sidebar-panel">
      <div className="sidebar-panel-title"> Top</div>
      <div className="sidebar-panel-content">
        {isLoading ? (
          <PostListSkeletons count={3} />
        ) : (
          <>
            {mostViewedPosts.map((item, idx) => (
              <PostCard
                key={idx + item.slug}
                post={item}
                className="with-row"
                hoverable={true}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export { MostViewed }
