import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./comment.sass"
import axios from "axios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import useCheckLogin from "@/hooks/useCheckLogin"
import { Date } from "../Date"
import { AvatarComponent } from "@components/Avatar"
import { CommentForm } from "../CommentForm"
import { Button, Space } from "antd"
import CommentSkeleton from "../CommentSkeleton"
import { SubComment } from "./subComment"
import Report from "../Report"

const Comment = ({ item }) => {
  const [subComments, setSubComments] = useState([])
  const [toggleReply, setToggleReply] = useState(false)
  const token = localStorage.getItem("login_token")
  const { t } = useTranslation()
  const { data } = useCheckLogin()
  const [position, setPosition] = useState(0)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios
      .get(`/comments/subcomments/${item.id}?position=${position}`)
      .then((res) => {
        setSubComments(res.data)
        setLoading(false)
      })
  }, [item])

  const onSendReply = async (reply) => {
    await axios
      .post(
        `/comments/subcomment/${item.id}`,
        { content: reply },
        { headers: { Authorization: `Token ${token}` } }
      )
      .then(() => {
        setLoading(true)
        axios.get(`/comments/subcomments/${item.id}`).then((res) => {
          setSubComments(res.data)
          setPosition(res.data.length)
          setLoading(false)
          setHasMoreData(true)
        })
        toast.info("Your reply have been sent !")
        setToggleReply(false)
      })
  }

  const loadMoreComments = async () => {
    setLoading(true)
    await axios
      .get(`/comments/subcomments/${item.id}?position=${position}`)
      .then((res) => {
        setLoading(false)
        setSubComments([...subComments, ...res.data])
        setPosition((prevState) => prevState + res.data.length)
        if (res.data.length === 0) setHasMoreData(false)
      })
  }

  return (
    <div className="comment-wrapper">
      <div className="comment">
        <AvatarComponent size={"large"} picture={item.avatar} />
        <div className="comment-wrapper">
          <div className="comment-details">
            <div className="comment-author">{item.user}</div>
            <div className="comment-content">{item.content}</div>
          </div>
          <div className="comment-footer">
            <div className="comment-footer-date">
              <Date time={item.createdDate} />
            </div>
            {data && !item.parentCommentId && (
              <div className="comment-reply">
                <div onClick={() => setToggleReply(!toggleReply)} className="">
                  {t("text.reply")}
                </div>
                <Report commentId={item.id} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="reply">
        {subComments.map((comment, index) => (
          <SubComment key={index} item={comment} />
        ))}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {loading && (
            <Space direction="vertical">
              {Array.from({ length: 2 }).map((_, i) => (
                <CommentSkeleton key={i} />
              ))}
            </Space>
          )}
        </div>
        {subComments && subComments.length > 0 && (
          <Button
            style={{
              margin: "10px 0",
              marginLeft: "50px",
              fontSize: "10px",
              width: "auto"
            }}
            disabled={!hasMoreData}
            type="link"
            size="small"
            onClick={loadMoreComments}
          >
            {t("buttons.load_more_response")}
          </Button>
        )}
        {toggleReply && (
          <>
            {data ? (
              <CommentForm
                onReply={onSendReply}
                cancelReply={() => setToggleReply(!toggleReply)}
                target="reply"
              />
            ) : (
              <div>
                <p style={{ opacity: 0.6, fontSize: "12px" }}>
                  {t("messages.reply_login_required")}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

Comment.propTypes = {
  item: PropTypes.instanceOf(Object)
}
Comment.defaultProps = {
  item: null
}

export { Comment }
