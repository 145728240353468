import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  isLogged: false,
  userInfo: {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogged: (state, action) => {
      state.isLogged = action.payload
    },
    setUserInfo: (state, action) => {
      state.isLogged = action.payload
    },
    logOut: (state)=> {
      state.isLogged = false,
      state.userInfo = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const { setIsLogged, setUserInfo, logOut } = authSlice.actions

export default authSlice.reducer
