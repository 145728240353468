import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lastPost: {},
  posts: []
}

export const homePostSlice = createSlice({
  name: 'homePost',
  initialState,
  reducers: {
    setHomePosts: (state, action) => {
      state.posts = [...action.payload]
    },
    setHomeLastPost: (state, action) => {
      state.lastPost = action.payload
    },
    addHomePosts: (state, action) => {
      state.posts = [...state.posts, ...action.payload]
    }
  },
})

// Action creators are generated for each case reducer function
export const { setHomeLastPost, setHomePosts, addHomePosts } = homePostSlice.actions

export default homePostSlice.reducer