import axios from "axios"
import { Component } from "react"
import PropTypes from "prop-types"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
  }

  state = { hasError: false, error: null }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error
    }
  }
  static get propTypes() {
    return {
      children: PropTypes.any,
      fallback: PropTypes.any
    }
  }
  componentDidCatch(error) {
    axios.post("/error/", {
      message: `${error}`
    })
    setTimeout(() => {
      if ( process.env.NODE_ENV === 'production' ) {
        window.location.replace("/")
      }
    }, 3000)
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }
    return this.props.children
  }
}
export default ErrorBoundary
