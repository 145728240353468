import React from "react"
import PropTypes from "prop-types"
import "./comment.sass"
import { Date } from "../Date"
import { AvatarComponent } from "@components/Avatar"

const SubComment = ({ item }) => {

  return (
    <div className="comment-wrapper">
      <div className="comment">
        <AvatarComponent size={"large"} picture={item.avatar} />
        <div className="comment-wrapper">
          <div className="comment-details">
            <div className="comment-author">{item.user}</div>
            <div className="comment-content">{item.content}</div>
          </div>
          <div className="comment-footer">
            <div className="comment-footer-date">
              <Date time={item.createdDate} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SubComment.propTypes = {
  item: PropTypes.instanceOf(Object)
}
SubComment.defaultProps = {
  item: null
}

export { SubComment }
