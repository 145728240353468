/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react"
import { Comment } from "@/components/Comment"
import { CommentForm } from "@components/CommentForm"
import PropTypes from "prop-types"
import "./style.sass"
import { get } from "@/axiosConfig"
import useCheckLogin from "@/hooks/useCheckLogin"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import CommentSkeleton from "@/components/CommentSkeleton"
import { Button, Space } from "antd"
import { useOnScreen } from "@/utils/useOnScreen"

const Comments = ({ postId, setCommentsCount }) => {
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(false)
  const { data } = useCheckLogin()
  const { t } = useTranslation()
  const commentSectionRef = useRef()
  const isCommentSectionVisible = useOnScreen(commentSectionRef)
  const [position, setPosition] = useState(0)
  const [hasMoreData, setHasMoreData] = useState(true)

  console.log(comments)

  useEffect(() => {
    if (isCommentSectionVisible && comments.length === 0) {
      setLoading(true)
      get(`/comments/post/${postId}?position=${position}`)
        .then((res) => {
          setComments(res.data)
          setPosition(res.data.length)
          setCommentsCount(res.data.count)
          setLoading(false)
        })
    }
  }, [isCommentSectionVisible, comments.length])

  const loadMoreComments = async () => {
    setPosition(position + 10)
    setLoading(true)
    await get(`/comments/post/${postId}?position=${position}`)
      .then((res) => {
        setComments([...comments, ...res.data])
        setPosition((prevState) => prevState + res.data.length)
        if (res.data.length === 0)
          setHasMoreData(false)
        setLoading(false)
      })
  }

  return (
    <>
      <div className="commentsComponent" ref={commentSectionRef}>
        {comments.map((item, index) => (
          <Comment key={index} item={item} />
        ))}
        {loading && (
          <Space direction="vertical">
            {Array.from({ length: 4 }).map((_, i) => (
              <CommentSkeleton key={i} />
            ))}
          </Space>
        )}
      </div>
      {data ? (
        <>
          <Button style={{ margin: '10px 0' }} disabled={!hasMoreData} onClick={loadMoreComments}>{t("buttons.load_more_comments")}</Button>
          <CommentForm postId={postId} setComments={setComments} setHasMoreData={setHasMoreData}/>
        </>
      ) : (
        <div style={{ display: "flex", fontSize: "12px" }}>
          <div style={{ opacity: 0.6, fontSize: "12px" }}>
            {t("messages.comment_login_required")}
          </div>
          <Link className="redirect-comment-login" to="/login">
            {t("login")}
          </Link>{" "}
          {t("text.or")}
          <Link className="redirect-comment-login" to="/register">
            {t("registration")}
          </Link>
        </div>
      )}
    </>
  )
}

export { Comments }
Comments.propTypes = {
  postId: PropTypes.number,
  setCommentsCount: PropTypes.func
}
