/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { HeartOutlined,  HeartFilled } from "@ant-design/icons"
import { Button } from "antd"
import axios from "axios"
import PropTypes from "prop-types"
import "./likes.sass"
import useCheckLogin from "@/hooks/useCheckLogin"
const Likes = ({ postId }) => {
  const [count, setCount] = useState(0)
  const [liked, setLiked] = useState(false)
  const token = localStorage.getItem("login_token")
  const { data } = useCheckLogin()

  const onToggleLike = async () => {
    await axios.post(
      "/likes/",
      { post_id: postId },
      { headers: { Authorization: `Token ${token}` } }
    )
    await axios
      .get(`/likes/post/${postId}/likes`)
      .then((res) => setCount(res.data.length))
    setLiked(!liked)
  }
  useEffect(() => {
    axios
      .get(`/likes/post/${postId}/likes`)
      .then((res) => setCount(res.data.length))
  }, [])

  useEffect(() => {
    axios
      .get(`/likes/user/checklike/${postId}`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then((res) => setLiked(res.data.liked))
  }, [])
  return (
    <div className="like-container">
      {data && (
        <>
          {liked ? (
            <Button shape="circle" danger size="large" type="primary" className="like-wrapper" onClick={() => onToggleLike()} icon={<HeartFilled />}/>
          ) : (
            <Button shape="circle" size="large" className="like-wrapper" onClick={() => onToggleLike()} icon={<HeartOutlined />}/>
          )}
        </>
      )}
      <span>{count > 1 ? ` ${count} likes` : ` ${count} like`}</span>
    </div>
  )
}

export default Likes

Likes.propTypes = {
  postId: PropTypes.number
}
