import React, { useEffect, useState } from "react"
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from "@ant-design/icons"
import { Button, Col, Form, Input, Row } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
// import { post } from "@/axiosConfig"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { StepsComponent } from "./steps"
import { checkCookies } from "@/utils/functions"
import axios from "axios"
import { toast } from "react-toastify"

const ContributorStep3 = () => {
  const [emailCode, setEmailCode] = useState(Cookies.get("emailCode") || "")
  const [password, setPassword] = useState("")
  const [cpassword, setCpassword] = useState("")
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const email = Cookies.get("email")
  const navigate = useNavigate()

  const { t } = useTranslation()

  useEffect(() => {
    const requiredKeys = ["firstname", "lastname", "phonenumber", "email"]

    if (!checkCookies(requiredKeys)) {
      navigate("/contributor/step-2")
    }
  }, [navigate])

  useEffect(() => {
    Cookies.set("step", 3, { expires: 7, path: "" })
  }, [])

  useEffect(() => {
    if (password !== cpassword) {
      setPasswordsMatch(false)
    } else {
      setPasswordsMatch(true)
    }
  }, [password, cpassword])

  const next = async () => {
    await axios
      .post("/auth/verify-email-code", { email, otp: emailCode })
      .then((res) => {
        if (res.data && res.data.status === 200) {
          Cookies.set("emailCode", emailCode, { expires: 7, path: "" })
          Cookies.set("password", password, { expires: 7, path: "" })
          navigate("/contributor/step-4")
        } else {
          toast.error("The email code is not correct!")
        }
      })
  }

  return (
    <MainLayout>
      <AuthSection>
        <StepsComponent currentStep={3} />
        <div className="auth-form">
          <Form
            name="normal_login"
            className="auth-form-body"
            initialValues={{ remember: true }}
            style={{
              minWidth: 400
            }}
          >
            <Form.Item
              name="emailCode"
              className="auth-form-item"
              rules={[
                {
                  required: true,
                  message:
                    "Please put your the code your receive on your email!"
                }
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email Code"
                value={emailCode}
                defaultValue={emailCode}
                type="text"
                onChange={(e) => setEmailCode(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="auth-form-item"
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                value={password}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="auth-form-item"
              name="cpassword"
              rules={[
                {
                  required: true,
                  message: "Please input confirmation password!"
                }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Confirmation Password"
                value={cpassword}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onChange={(e) => setCpassword(e.target.value)}
              />
            </Form.Item>
            {!passwordsMatch && password.length > 0 && (
              <p style={{ color: "red" }}>
                {t("messages.passwords_mismatch")}
              </p>
            )}
            <Row gutter={12} justify="space-between">
              <Col>
                <Button
                  htmlType="submit"
                  icon={<ArrowLeftOutlined />}
                  type="primary"
                  className="auth-form-button"
                  onClick={() => navigate("/contributor/step-2")}
                ></Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="auth-form-button"
                  icon={<ArrowRightOutlined />}
                  onClick={() => next()}
                ></Button>
              </Col>
            </Row>
          </Form>
          <div className="auth-form-footer">
            <Button type="text" className="auth-form-forgot">
              <Link to="/login">{t("login")}</Link>
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { ContributorStep3 }
