/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Modal, Form } from "antd"
import PropTypes from "prop-types"
import axios from "axios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { Radio, Space } from "antd"
const ReportModal = ({ open, setOpen, postId, commentId }) => {
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState("")
  const token = localStorage.getItem("login_token")
  const { t } = useTranslation()
  const REASONS = [
    {
      title: t("report.hate_speech")
    },
    {
      title: t("report.harassment")
    },
    {
      title: t("report.violence")
    },
    {
      title: t("report.sexual")
    },
    {
      title: t("report.illegal")
    },
    {
      title: t("report.fake_news")
    },
    {
      title: t("report.spam")
    },
    {
      title: t("report.copywright")
    },
    {
      title: t("report.inapropriate")
    },
    {
      title: t("report.minors_exploitation")
    },
    {
      title: t("report.impersonation")
    },
    {
      title: t("report.unauthorized")
    },
    {
      title: t("report.self_harm")
    },
    {
      title: t("report.doxxing")
    },
    {
      title: t("report.community_violation")
    }
  ]
  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  }
  const handleOk = async () => {
    setLoading(true)
    if ((postId && reason !== "") || (commentId && reason !== "")) {
      const data = { post_id: postId, reason, comment_id: commentId }
      await axios
        .post("/post/report", data, {
          headers: { Authorization: `Token ${token}` }
        })
        .then((res) => {
          if (res.status === 201) {
            toast.info("Thank you for the report this post will be review !")
            setLoading(false)
            setReason(false)
            handleCancel()
          }
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
        })
    } else {
      toast.info(t("messages.error_occurred"))
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const onChange = (e) => {
    setReason(e.target.value)
  }

  return (
    <div>
      <Modal
        title={t("messages.report_title")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="nest-messages"
          style={{
            maxWidth: 600
          }}
        >
          <Radio.Group onChange={onChange} value={reason} disabled={loading}>
            <Space direction="vertical">
              {REASONS.map((item, index) => (
                <Radio key={index} value={item.title}>
                  {item.title}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form>
      </Modal>
    </div>
  )
}

export default ReportModal

ReportModal.propTypes = {
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  postId: PropTypes.number,
  commentId: PropTypes.number
}
