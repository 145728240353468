import React from "react"
import PropTypes from "prop-types"
import Avatar from "antd/lib/avatar/avatar"
import defaultPicture from '@assets/images/user.png'

const AvatarComponent = ({ picture, size }) => {

  return (
    <>
      {picture ? (
        <Avatar
          size={size}
          shape="circle"
          src={`${process.env.REACT_APP_BACKEND_URL}/files/${picture}`}
        />
      ) : (
        <Avatar
          shape="circle"
          size={size}
          src={defaultPicture}
        />
      )}
    </>
  )
}

AvatarComponent.propTypes = {
  size: PropTypes.any,
  picture: PropTypes.string
}

AvatarComponent.defaultProps = {
  picture: null,
  size: 'small'
}

export { AvatarComponent }