import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  title: '',
  description: '',
  image: '',
  keywords: ''
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setPageHeader: (state, action) => {
      state.title = action.payload.title || state.title;
      state.image = action.payload.image || state.image;
      state.description = action.payload.description || state.description;
      state.keywords = action.payload.keywords || state.keywords
    }
  },
})

// Action creators are generated for each case reducer function
export const { setPageHeader } = headerSlice.actions

export default headerSlice.reducer