import React from "react"
import PropTypes from "prop-types"
import { MailOutlined, UserOutlined } from "@ant-design/icons"

import { Steps } from "antd"
import { useTranslation } from "react-i18next"
const StepsComponent = ({ currentStep }) => {
  const { t } = useTranslation()
  return (
    <div style={{ marginBottom: "50px" }}>
      <Steps
        items={[
          {
            title: t("steps.full_name_email"),
            status: currentStep >= 1 ? "finish" : "wait",
            icon: <MailOutlined />
          },
          {
            title: t("steps.password"),
            status: currentStep >= 2 ? "finish" : "wait",
            icon: <UserOutlined />
          }
        ]}
      />
    </div>
  )
}

StepsComponent.propTypes = {
  currentStep: PropTypes.number.isRequired
}

export { StepsComponent }
