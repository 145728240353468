import React, { Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import "@style/index.sass";
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module';
import './i18n/index';
import axios from "axios"
import { ToastContainer } from 'react-toastify'


axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL
const tagManagerArgs = {
  gtmId: "GTM-MGN2Z9H"
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div>Loading....</div>}>
      <App />
      <ToastContainer newestOnTop />
    </Suspense>
  </StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
