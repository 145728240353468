/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { i18n } from "@/i18n";
import { Dropdown, Button } from "antd";
import EnImage from "@assets/images/en.png";
import FrImage from "@assets/images/fr.png";
import PropTypes from "prop-types"
const LangageSelection = ({ Icon, onClick, value }) => (
  <div onClick={() => onClick && onClick(value)}><img src={Icon}/></div>
)

const LanguageSelector = () => {

  const selectedLanguage = i18n.language;
  const [selectedItem, setSelectedItem] = useState(i18n.language)

  const chooseLanguage = (value) => {
    localStorage.setItem("selectedLanguage", value);
    window.location.reload();
  }
  const languageItems = [
    {
      value: 'fr',
      icon: FrImage,
      onClick: chooseLanguage
    },
    {
      value: 'en',
      icon: EnImage,
      onClick: chooseLanguage
    }
  ]

  useEffect(() => {
    setSelectedItem(languageItems.find((item) => item.value === selectedLanguage));
  }, [selectedLanguage])
  

  return (
    <>
      <Dropdown
        style={{ width: 120 }}
        onChange={chooseLanguage}
        bordered={false}
        menu={{
          items: languageItems.filter((item) => item.value !== selectedLanguage).map((item) => ({ value: item.value, label: (<LangageSelection Icon={item.icon} value={item.value} onClick={item.onClick}/>) })),
          selectable: true,
          defaultSelectedKeys: ['3'],
        }}
        placement="bottom"
        arrow={{ pointAtCenter: true }}
      >
        <Button shape="circle">
          <LangageSelection Icon={selectedItem.icon} value={selectedItem.value}/>
        </Button>
      </Dropdown>
    </>
  );
};

LangageSelection.prototype = {
  Icon: PropTypes.any,
  onClick: PropTypes.func,
  value: PropTypes.string
}
LangageSelection.defaultprops = {
  Icon: <></>
}

export { LanguageSelector };