/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"

import Container from "@components/Container/Container"
import { Helmet } from "react-helmet"
import { Input, Typography, Col, Row, Badge } from "antd"
import PropTypes from "prop-types"

const { Search } = Input
const { Title, Text } = Typography
import { SkeletonPostCard } from "@/components/SkeletonPostCard"
import PostCard from "@/components/Post"
import { useSelector } from "react-redux"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { post, get } from "../axiosConfig"
import { useTranslation } from "react-i18next"
import axios from "axios"

const SearchResults = ({ posts }) => (
  <>
    <Badge count={posts.length}>
      <Title level={3}>Résultat(s)</Title>
    </Badge>
    {posts && posts.length > 0 ? (
      <Row gutter={[32, 32]}>
        {posts.map((item, index) => (
          <Col key={index} md={6} xs={24}>
            <PostCard post={item} hoverable={true} />
          </Col>
        ))}
      </Row>
    ) : (
      <Row>
        <Text>
          Désolé, aucune correspondance n&apos;a été trouvée pour votre
          recherche. Essayez avec d&apos;autres mots-clés.
        </Text>
      </Row>
    )}
  </>
)

const SearchPage = () => {
  const [searching, setSearching] = useState(false)
  const [posts, setPosts] = useState([])
  const { searchValue } = useSelector((state) => state.searchBar)
  const [initialSearchDone, setInitialSearchDone] = useState(false)

  const { t } = useTranslation()

  const onSearch = async (value) => {
    setSearching(true)
    setInitialSearchDone(true)
    await get("/post/search", { search_query: value })
      .then((response) => {
        setSearching(false)
        setPosts(response.data)
      })
      .catch(async (error) => {
        setSearching(false)
        await post("/error/", {
          message: `${error.message}`
        })
      })
  }

  useEffect(() => {
    document.title = "Recherche"
    if (searchValue && searchValue.length > 0) onSearch(searchValue)
  }, [])

  return (
    <MainLayout>
      <Container>
        <Title>{t("search.name")}</Title>
        <p>{t("search.description")}</p>
        <Search
          placeholder={t("search.placeholder")}
          disabled={searching}
          loading={searching}
          onSearch={onSearch}
          defaultValue={searchValue}
          size="large"
        />
        <br />
        <br />

        {initialSearchDone ? (
          searching ? (
            <Row gutter={[32, 32]}>
              <Col md={6} xs={24}>
                <SkeletonPostCard />
              </Col>
              <Col md={6} xs={24}>
                <SkeletonPostCard />
              </Col>
              <Col md={6} xs={24}>
                <SkeletonPostCard />
              </Col>
              <Col md={6} xs={24}>
                <SkeletonPostCard />
              </Col>
            </Row>
          ) : (
            <SearchResults posts={posts} />
          )
        ) : (
          <p>{t("search.start")}</p>
        )}
      </Container>
    </MainLayout>
  )
}

SearchResults.propTypes = {
  posts: PropTypes.array
}

SearchResults.defaultProps = {
  posts: []
}
export { SearchPage }
