import React from 'react';
import PropTypes from "prop-types";
import {
  LoadingOutlined,
  PhoneOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined
} from '@ant-design/icons';

import { Steps } from 'antd';
const StepsComponent = ({ currentStep, loading = false }) => {

  return (
    <div style={{ marginBottom: "50px" }}>
      <Steps
        items={[
          {
            title: 'Étape 1 - Numéro de téléphone',
            status: currentStep >= 1 ? 'finish' : 'wait',
            icon: <PhoneOutlined />,
          },
          {
            title: 'Étape 2 - Nom et prénom',
            status: currentStep >= 2 ? 'finish' : 'wait',
            icon: <UserOutlined />,
          },
          {
            title: 'Étape 3 - Email et mot de passe',
            status: currentStep >= 3 ? 'finish' : 'wait',
            icon: <SolutionOutlined />,
          },
          {
            title: 'Étape 4 - Photo de profil',
            status: currentStep >= 4 ? 'finish' : 'wait',
            icon: <SmileOutlined />,
          },
          {
            title: 'Résumé',
            status: currentStep >= 5 ? 'finish' : 'wait',
            icon: loading ? <LoadingOutlined /> : <SmileOutlined />
          }
        ]}
      />
    </div>
  )
};

StepsComponent.propTypes = {
  currentStep: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired
};

export { StepsComponent }