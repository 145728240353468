import { Divider, Skeleton, Space } from "antd"
import React from "react"

const CommentSkeleton = () => {
  return (
    <Space style={{ marginTop: "5px", marginBottom: "5px" }}>
      <Skeleton.Avatar active size={"default"} shape={"circle"} />
      <Skeleton.Input active size={"default"} />
      <Divider />
    </Space>
  )
}

export default CommentSkeleton
