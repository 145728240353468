import React from "react";
import { MostViewed } from "../MostViewed";
import './sidebar.sass';
const SideBar = () => {

  return (
    <div className="sidebar">
      <MostViewed/>
    </div>
  );
}

export { SideBar };