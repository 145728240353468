import React from "react"
import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import LogOutButton from "./LogOutButton"
import useCheckLogin from "@/hooks/useCheckLogin"
import useGetUser from "@/hooks/useGetUser"
import { Link } from "react-router-dom"
import { AvatarComponent } from "@components/Avatar"

const ProfilDropdown = () => {
  const { data } = useCheckLogin()
  const { data: userInfo } = useGetUser()
  const dropDownItems = [
    {
      label: <Link to="/profil">Profil</Link>,
      key: "0"
    },
    {
      label: <LogOutButton />,
      key: "1"
    }
  ]
  return (
    <Dropdown
      menu={{
        items: data ? dropDownItems : []
      }}
      trigger={["click"]}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <AvatarComponent size={'large'} picture={userInfo.avatar}/>
          {/* {data ? `${userInfo.lastname} ${userInfo.firstname}` : ""} */}
          <DownOutlined style={{ fontSize: "12px" }} />
        </Space>
      </a>
    </Dropdown>
  )
}

export default ProfilDropdown
