import React from "react"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { logOut } from "@/slices/authSlice"

const LogOutButton = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const { isLogged } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const signOut = () => {
    localStorage.removeItem("login_token")
    dispatch(logOut())
    navigate(`/${lang}/login`)

  }
  return (
    <Button
      onClick={() => signOut()}
      type={"primary"}
      htmlType="submit"
      className="login-button"
      danger
    >
      {isLogged ? t("sign_out") : t("login")}
    </Button>
  )
}

export default LogOutButton
