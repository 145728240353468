import { Button, Col, Form, Input, Modal, Row, Steps } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { PhoneOutlined } from "@ant-design/icons"
import { toast } from "react-toastify"
import { post } from "@/axiosConfig"
import { useTranslation } from "react-i18next"
import Lottie from "react-lottie"
import animationData from "../../assets/lotties/lottie-validate.json"
import Cookies from "js-cookie"

const PhoneNumberSteps = ({ step }) => {
  return (
    <Steps
      size="small"
      current={step}
      items={[
        {
          title: ""
        },
        {
          title: ""
        },
        {
          title: ""
        }
      ]}
    />
  )
}

const EditPhoneNumberModal = ({ open, setOpen }) => {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [step, setStep] = useState(0)
  const [codeSent, setCodeSent] = useState(false)
  const [verifyWait, setVerifyWait] = useState(false)
  const [codeSentNumber, setCodeSentNumber] = useState(0)
  const [otp, setOtp] = useState("")
  const [otpConfirmed, setOtpConfirmed] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const phoneChangeEnable = Cookies.get("phonechange")

  useEffect(() => {
    if (!verifyWait) {
      setTimeout(() => {
        setVerifyWait(true)
      }, 6000 * 5)
    }
  }, [verifyWait])
  useEffect(() => {
    if (codeSentNumber === 3) {
      Cookies.set("phonechange", "blocked", { expires: 3, path: "" })
      toast.info(t("messages.phone_edition_blocked"))
    }
  }, [codeSentNumber])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }
  const sendCode = useCallback(async () => {
    setLoading(true)
    if (phoneChangeEnable !== "blocked") {
      if (phoneNumber === "") {
        toast.error(t("messages.phone_number_required"))
        setLoading(false)
      } else {
        await post("/twilio/sendcode", { phonenumber: phoneNumber })
          .then((res) => {
            if (res.data && res.data.status === 201) {
              setCodeSent(true)
              setStep(step + 1)
              setLoading(false)
              setCodeSentNumber(codeSentNumber + 1)
              toast.success(t("messages.message_sent"))
            }
          })
          .catch(() => {
            setLoading(false)
            toast.error(t("messages.message_send_error"))
          })
      }
    } else {
      toast.info(t("messages.phone_update_disabled"))
    }
  }, [phoneNumber])

  const verifyCode = useCallback(() => {
    setLoading(true)
    if (otp === "") {
      toast.error(t("messages.otp_required"))
      setLoading(false)
    } else {
      post(`/twilio/verify`, { phoneNumber, otp })
        .then((res) => {
          setLoading(false)
          if (res.data && res.data.status === 200) {
            setOtpConfirmed(true)
            setStep(step + 1)
          }
          if (res.data && res.data.status === 400) {
            toast.error(t("messages.incorrect_code"))
          }
          if (res.data && res.data.status === 404) {
            toast.log(t("messages.code_expired"))
          }
        })
        .catch(() => {
          toast.error(t("messages.error_occurred"))
        })
    }
  }, [otp, phoneNumber])

  const handleOk = () => {
    setOpen(!open)
  }
  const handleCancel = () => {
    setOpen(!open)
  }
  let content = (
    <Form>
      <Form.Item
        name={t("input.phone_number")}
        rules={[
          { required: true, message: t("input.errors.phone_number_empty") }
        ]}
      >
        <Input
          prefix={<PhoneOutlined className="site-form-item-icon" />}
          placeholder="+XXX-XXXXXXXX"
          value={phoneNumber}
          // defaultValue={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Form.Item>
      <p style={{ marginTop: "8px" }}>
        <Button
          type="primary"
          className="auth-form-button"
          onClick={() => sendCode()}
          loading={loading}
          disabled={loading}
          block
        >
          {t("buttons.get_the_code")}
        </Button>
      </p>
    </Form>
  )
  if (step === 0) {
    content = (
      <>
        <div>{t("text.enter_phone_number")}</div>
        <Form>
          <Form.Item
            name={t("input.phone_number")}
            rules={[
              { required: true, message: t("input.errors.phone_number_empty") }
            ]}
          >
            <Input
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder="+XXX-XXXXXXXX"
              value={phoneNumber}
              defaultValue={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <p style={{ marginTop: "8px" }}>
            <Button
              type="primary"
              className="auth-form-button"
              onClick={() => sendCode()}
              loading={loading}
              disabled={loading}
              block
            >
              {t("buttons.get_the_code")}
            </Button>
          </p>
        </Form>
      </>
    )
  }
  if (step === 1 && codeSent) {
    content = (
      <>
        <>{t("text.enter_code_send")}</>
        <Form>
          <Form.Item
            name="otp"
            rules={[
              { required: true, message: t("input.errors.error_code_send") }
            ]}
          >
            <Input
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder="123456"
              value={otp}
              defaultValue={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Item>
          <p style={{ marginTop: "8px" }}>
            <Button
              htmlType="submit"
              type="primary"
              className="auth-form-button"
              onClick={() => verifyCode()}
              loading={loading}
              disabled={loading}
              block
            >
              {t("buttons.verify")}
            </Button>
            <p>
              {t("text.code_not_receive")}
              <Button
                type="link"
                className="auth-form-button"
                onClick={() => sendCode()}
                disabled={verifyWait}
              >
                {t("buttons.resend")}
              </Button>
            </p>
          </p>
        </Form>
      </>
    )
  }
  if (step === 2 && otpConfirmed) {
    content = (
      <Row>
        <Col xs={24}>
          <Lottie options={defaultOptions} width={200} height={200} />
        </Col>
        <Col xs={24}>
          <p className="text-center" style={{ textAlign: "center" }}>
            {t("text.phone_number_changed")}
          </p>
        </Col>
      </Row>
    )
  }
  return (
    <Modal
      title={t("messages.edit_phone_number_modal")}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
    >
      <PhoneNumberSteps step={step} />
      <br />
      {content}
    </Modal>
  )
}

export default EditPhoneNumberModal

PhoneNumberSteps.propTypes = {
  step: PropTypes.number
}
EditPhoneNumberModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}
