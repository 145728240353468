import React from "react"
import { Skeleton } from "antd"


const SkeletonPostCard = ()=> (
  <>
    <Skeleton.Button block active size={'large'} shape={'square'} width={'100%'}/>
    <Skeleton active title={{ width: '100%', rows: 1 }}/>
  </>
);

export { SkeletonPostCard };
