import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  posts: []
}

export const mostViewedPostSlice = createSlice({
  name: 'mostViewedPosts',
  initialState,
  reducers: {
    setMostViewedPosts: (state, action) => {
      state.posts = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setMostViewedPosts } = mostViewedPostSlice.actions

export default mostViewedPostSlice.reducer