import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from "prop-types";
const Date = ({ time }) => {
  moment.locale('fr');
  return (
    <time title={moment(time).format('MM/DD/YYYY')}>{moment(time).fromNow(true)}</time>
  )
};

Date.defaultProps = {
  time: 0
}
Date.propTypes = {
  time: PropTypes.any
};

export { Date };