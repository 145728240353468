import React, { useEffect, useState, useCallback } from "react"
import { Col, Row, Typography, Button } from "antd"
import slugify from "react-slugify"
import PropTypes from "prop-types"
import PorstCard from "@/components/Post"
import Container from "./Container/Container"
import { useDispatch, useSelector } from "react-redux"
import { setCategoryPosts, addCategoryPosts } from "@slices/categoryPostSlice"
import { SkeletonPostCard } from "./SkeletonPostCard"
import Lottie from "react-lottie"
import animationData from "../assets/lotties/animation_empty.json"
import { useTranslation } from "react-i18next"
import { get } from "@/axiosConfig"

export default function HomeCategoryView({ category_name }) {
  const [posts, setPosts] = useState([])
  const { categoryPosts } = useSelector((state) => state)
  const [lastDoc, setLastDoc] = useState(null)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { Title } = Typography
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const fetchMoreData = useCallback(() => {
    setIsLoadingMore(true)
    get("/post/fetchcategorypostdata", {
      name: category_name,
      last_post: categoryPosts[slugify(category_name)].lastPost.id
    })
      .then((res) => {
        if (res.data.length == 0) {
          setHasMoreData(false)
          setIsLoadingMore(false)
        } else {
          let lastPost = res.data[res.data.length - 1]
          const data = {
            name: slugify(category_name),
            posts: res.data,
            lastPost
          }
          dispatch(addCategoryPosts(data))
          setPosts([...posts, ...res.data])
          setLastDoc(lastPost)
          setIsLoadingMore(false)
        }
      })
  }, [lastDoc, category_name, categoryPosts[(slugify(category_name), posts)]])

  const fetchData = () => {
    setIsLoading(true)
    get("/post/fetchcategorypostdata", {
      name: category_name,
      last_post: 0
    })
      .then((res) => {
        if (res.data) {
          let lastPost = res.data[res.data.length - 1]
          const data = {
            name: slugify(category_name),
            posts: res.data,
            lastPost
          }
          dispatch(setCategoryPosts(data))
          setPosts([...res.data])
          setLastDoc(lastPost)
          setIsLoading(false)
        }
      })
  }

  useEffect(() => {
    fetchData()
  }, [category_name])
  return (
    <>
      <Container>
        <Title level={1} className="page-title" style={{ color: "red" }}>
          {category_name}
        </Title>
        {isLoading ? (
          <Row gutter={[32, 32]}>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={[32, 32]}>
              {posts.map((item, index) => (
                <Col key={index} md={8} xs={24}>
                  <PorstCard post={item} imageRatio="ratio_165" />
                </Col>
              ))}
              {isLoadingMore && (
                <>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                </>
              )}
            </Row>

            {posts.length === 0 && (
              <Row>
                <Col xs={24}>
                  <Lottie options={defaultOptions} width={200} height={200} />
                </Col>
                <Col xs={24}>
                  <p className="text-center" style={{ textAlign: "center" }}>
                    {t("empty_list")}
                  </p>
                </Col>
              </Row>
            )}
            {!isLoading && hasMoreData &&
            categoryPosts[slugify(category_name)] &&
            categoryPosts[slugify(category_name)].lastPost && (
              <div style={{ textAlign: "center", marginTop: 30 }}>
                <Button
                  danger
                  loading={isLoadingMore}
                  onClick={fetchMoreData}
                  shape="round"
                >
                  {t("buttons.load_more")}
                </Button>
              </div>
            )}
          </>
        )}
      </Container>
    </>
  )
}

HomeCategoryView.propTypes = {
  category_name: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.instanceOf(Object))
}
HomeCategoryView.defaultProps = {
  category_name: null,
  articles: []
}
