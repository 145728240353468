import React from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"

const Header = () => {
  const { title, image, description, keywords } = useSelector(
    (state) => state.header
  )
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={window.location.href} />
      <meta name="robots" content="index, follow" />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:site_name" content="urbankiff.com" />
      <meta property="og:locale" content="en_US" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image} />
      {/*<!--  Non-Essential, But Required for Analytics --> */}
      <meta property="fb:app_id" content="109825489394650" />
      <meta name="twitter:site" content="@urbankiffoff" />
      {/* Mobile */}
    </Helmet>
  )
}

export { Header }
