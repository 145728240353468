/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"

import Container from "@components/Container/Container"
import { Button, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  hideSearchBar,
  resetSearchValue,
  setSearchValue
} from "@slices/searchBarSlice"
import { SearchOutlined } from "@ant-design/icons"
import "./style.sass"
import { useTranslation } from "react-i18next"

const SearchBar = () => {
  const searchBar = useSelector((state) => state.searchBar.searchBar)
  const [inputValue, setInputValue] = useState("")
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const hiddenRoutes = ["/recherche"]
  const shouldShowSearchBar = !hiddenRoutes.includes(location.pathname)

  useEffect(() => {
    dispatch(hideSearchBar())
    dispatch(resetSearchValue())
    setInputValue("")
  }, [dispatch, location.pathname])

  const handleSearch = (value) => {
    if (value && value.length > 0) {
      dispatch(setSearchValue(value))
      navigate(`/${lang}/recherche`)
    }
  }

  return (
    <>
      {shouldShowSearchBar && searchBar && (
        <Container>
          <div className="search-container">
            <Input
              className="search-input"
              placeholder={t("search.placeholder")}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onPressEnter={() => handleSearch(inputValue)}
              min={1}
            />
            <div className="search-icon">
              <Button
                onClick={() => handleSearch(inputValue)}
                type="button"
                className="border-none"
                icon={<SearchOutlined />}
              ></Button>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

export { SearchBar }
