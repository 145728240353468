import React from "react"
import { ImageWL } from "@components/Image"
import PropTypes from "prop-types"

const SinglePostImage = ({ image }) => (
  <div className="post-image-container">
    <ImageWL
      className="original"
      src={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}tr:w-60,h-39/${image}`}
      large={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}tr:w-1920/${image}`}
      showOriginal={true}
    />
  </div>
)
SinglePostImage.propTypes = {
  image: PropTypes.string
}
export { SinglePostImage }