import { configureStore } from '@reduxjs/toolkit'
import categoryReducer from "./categorySlice"
import categoryPostReducer from "./categoryPostSlice"
import headerReducer from "./headerSlice"
import searchBarReducer from "./searchBarSlice"
import homePostReducer from "./homePostSlice"
import mostViewedPostReducer from "./mostViewedPostSlice"
import authReducer from "./authSlice"

export const store = configureStore({
  reducer: {
    "category": categoryReducer,
    "categoryPosts": categoryPostReducer,
    "header": headerReducer,
    "searchBar": searchBarReducer,
    "homePosts": homePostReducer,
    "mostViewedPosts": mostViewedPostReducer,
    "auth": authReducer
  },
})
