import { initializeApp } from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyC3QO1tvYwbaPCPdvt_eYPYH-nUIqEi_S8",
  authDomain: "ubnews-f3bbc.firebaseapp.com",
  projectId: "ubnews-f3bbc",
  storageBucket: "ubnews-f3bbc.appspot.com",
  messagingSenderId: "266950397935",
  appId: "1:266950397935:web:ab60291c24da26a23b301a",
  measurementId: "G-NFJEL91108"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export default app