import Cookies from "js-cookie"

function checkCookies(keys) {
  for (const key of keys) {
    if (!Cookies.get(key)) {
      return false
    }
  }
  return true
}

function deleteCookiesWithPath(keysToDelete, path) {
  keysToDelete.forEach(key => {
    Cookies.remove(key, { path });
  });
}



export { checkCookies, deleteCookiesWithPath }