

import AWS from 'aws-sdk'
const S3_BUCKET = process.env.REACT_APP_WASABI_BUCKETNAME
const REGION = process.env.REACT_APP_WASABI_REGION


AWS.config.update({
  accessKeyId: process.env.REACT_APP_WASSABI_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_WASABI_SECRET_KEY
})

const myBucket = new AWS.S3({
  correctClockSkew: true,
  params: { Bucket: S3_BUCKET },
  region: REGION,
  endpoint: 'https://s3.eu-central-1.wasabisys.com',
  accessKeyId: process.env.REACT_APP_WASSABI_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_WASABI_SECRET_KEY
})
function generateUUID() {
  let d = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + (Math.random() * 32)) % 32 | 0
    d = Math.floor(d / 32)
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(32)
  })
  return uuid
}

const useUploadFile = (file, callBack = null) => {
  const params = {
    ACL: 'public-read',
    Body: file,
    Bucket: S3_BUCKET,
    Key: `images/${generateUUID()}.${file.name.split('.')[file.name.split('.').length - 1]}`
  }

  myBucket.upload(params)
    .on('httpUploadProgress', (evt) => {
      console.log(Math.round((evt.loaded / evt.total) * 100))
    })
    .send((err, data) => {
      if (err) {
        console.log(err)
      } else {
        if (callBack) {
          callBack(data)
        }
      }
    })

}


const useDeleteFile = (file, callBack = null) => {
  const params = {
    Bucket: S3_BUCKET,
    Key: file
  }
  myBucket.deleteObject(params, function (err, data) {
    if (err) console.log(err, err.stack) // an error occurred
    else {
      if (callBack) {
        callBack(data)
      }
    }

  })
}
export { useUploadFile, useDeleteFile }
