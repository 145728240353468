import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import "./loader.css";

const Loader = ({ size }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;
  return (
    <div className="loader-container">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  size: PropTypes.number,
};
