import React from 'react';
import { Layout } from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Footer } = Layout;

const AppFooter = () => {
  const { t } = useTranslation();


  return (
    <Footer style={{ textAlign: 'center', backgroundColor: "black", color: 'white' }}>
      <p>
        <NavLink to="/" style={{ color: 'white' }}>Urbenhits</NavLink>
        <span> | </span>
        <NavLink to="#" style={{ color: 'white' }}>{t('menu.music')}</NavLink>
        <span> | </span>
        <NavLink to="#" style={{ color: 'white' }}>{t('menu.videos')}</NavLink>
        <span> | </span>
        <NavLink to="#" style={{ color: 'white' }}>{t('menu.news')}</NavLink>
        <span> | </span>
        <NavLink to="#" style={{ color: 'white' }}>{t('menu.buzz')}</NavLink>
        <span> | </span>
        <NavLink to="#" style={{ color: 'white' }}>{t('menu.melody')}</NavLink>
      </p>
      <p>
        <NavLink to="#" style={{ color: 'white' }}>{t('footer.legal_notice')}</NavLink> |{' '}
        <NavLink to="#" style={{ color: 'white' }}>{t('footer.data_and_cookies')}</NavLink> |{' '}
        <NavLink to="#" style={{ color: 'white' }}>{t('footer.terms_of_service')}</NavLink>
      </p>
      <p>
        <NavLink to="#" style={{ color: 'white' }}>{t('footer.advertisements')}</NavLink> |{' '}
        <NavLink to="#" style={{ color: 'white' }}>{t('footer.careers')}</NavLink> |{' '}
        <NavLink to="#" style={{ color: 'white' }}>{t('footer.contact_us')}</NavLink>
      </p>

      <p>
        <a href="https://www.facebook.com/urbenhits" target="_blank" rel="noreferrer" style={{ color: 'white' }}><FacebookOutlined style={{ fontSize: '24px', marginRight: '10px' }} /></a>
        <a href="https://twitter.com/urbenhits" target="_blank" rel="noreferrer" style={{ color: 'white' }}><TwitterOutlined style={{ fontSize: '24px', marginRight: '10px' }} /></a>
        <a href="https://www.instagram.com/urbenhits/" target="_blank" rel="noreferrer" style={{ color: 'white' }}><InstagramOutlined style={{ fontSize: '24px', marginRight: '10px' }} /></a>
        <a href="https://www.youtube.com/channel/UC92CyBcUqriR_RjkXQP-ISg?view_as=subscriber" target="_blank" rel="noreferrer" style={{ color: 'white' }}><YoutubeOutlined style={{ fontSize: '24px', marginRight: '10px' }} /></a>
      </p>
      <p style={{ color: 'white' }}>©2023 Urbenhits</p>
    </Footer>
  );
};

export { AppFooter };
