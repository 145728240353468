import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchBar: false,
  searchValue: ""
}

export const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState,
  reducers: {
    toggleSearchBar: (state) => {
      state.searchBar = !state.searchBar
    },
    hideSearchBar: (state) => {
      state.searchBar = false
      state.searchValue = " "
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    resetSearchValue: (state) => {
      state.searchValue = ""
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleSearchBar, hideSearchBar, setSearchValue, resetSearchValue } = searchBarSlice.actions

export default searchBarSlice.reducer