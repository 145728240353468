import React from "react"
import { Modal, Form, Input } from "antd"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const EditModal = ({
  handleOk,
  handleCancel,
  open,
  user,
  onHandleChange,
  loading
}) => {
  const { t } = useTranslation()
  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  }
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!"
    },
    number: {
      range: "${label} must be between ${min} and ${max}"
    }
  }

  return (
    <div>
      <Modal
        title={t("messages.edit_profile_modal")}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          {...layout}
          name="nest-messages"
          style={{
            maxWidth: 600
          }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "lastname"]}
            label={t("input.last_name")}
            rules={[
              {
                type: "string"
              }
            ]}
          >
            <Input
              name="lastname"
              defaultValue={user.lastname}
              onChange={onHandleChange}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name={["user", "firstname"]}
            label={t("input.first_name")}
            rules={[
              {
                type: "string"
              }
            ]}
          >
            <Input
              name="firstname"
              defaultValue={user.firstname}
              disabled={loading}
              onChange={onHandleChange}
            />
          </Form.Item>
          <Form.Item
            name={["user", "email"]}
            label="Email"
            rules={[
              {
                type: "email"
              }
            ]}
          >
            <Input
              disabled
              defaultValue={user.email}
              onChange={onHandleChange}
            />
          </Form.Item>
          <Form.Item
            name={["user", "phonenumber"]}
            label={t("input.phone_number")}
          >
            <Input
              name="contact"
              type="text"
              placeholder="+XXXXXXXXXXXX"
              defaultValue={user.contact}
              disabled
              onChange={onHandleChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default EditModal

EditModal.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  open: PropTypes.bool,
  user: PropTypes.object,
  onHandleChange: PropTypes.func,
  loading: PropTypes.bool
}
