import React from "react"
import { Button } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SearchButton = () =>{
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const lang = i18n.language
  const SearchBar = () => {
    navigate(`/${lang}/recherche`)
  }
  return (
    <Button
      onClick={SearchBar}
      type="button"
      shape="circle"
      icon={<SearchOutlined />}
    ></Button>
  )
}

export { SearchButton }
