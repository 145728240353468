import { Input, Modal } from "antd"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import axios from "axios"

const UsernameModal = ({ open, setOpen, data }) => {
  const [username, setUsername] = useState("")
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("login_token")
  const { t } = useTranslation()

  const handleOk = () => {
    setLoading(true)
    const form_data = { username }
    axios
      .put(`/user/${data.id}`, form_data, {
        headers: { authorization: `Token ${token}` }
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false)
          toast.info(t("text.username_updated"))
          window.location.reload(false)
        } else if (res.status === 500) {
          setLoading(false)
          toast.info(t("text.username_existed"))
        }
      })
      .catch((error) => console.log(error))
    setOpen(!open)
  }
  const handleCancel = () => {
    setOpen(!open)
  }

  return (
    <Modal
      title={t("messages.username_modal")}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
    >
      <label htmlFor="username">{t("text.enter_username")}</label>
      <Input
        name="username"
        placeholder={t("messages.username_modal")}
        defaultValue={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={loading}
      />
    </Modal>
  )
}

export default UsernameModal

UsernameModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.instanceOf(Object)
}
