
const useSite = () => {

  const setPageTitle = title => document.title = title || process.env.APP_NAME

  return {
    setPageTitle
  }
}

export { useSite }