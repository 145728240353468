import { Space, Tooltip } from "antd"
import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share"
export default function Socials() {
  const currentPath = window.location.href
  return (
    <Space style={{ padding: "16px 0px" }}>
      <Tooltip placement="right" title="">
        <FacebookShareButton url={currentPath} hashtag="" title="">
          <FacebookIcon round={true} size={40}/>
        </FacebookShareButton>
        <FacebookShareCount url={currentPath}/>
      </Tooltip>
      <Tooltip placement="right" title="">
        <TwitterShareButton url={currentPath} hashtag="urbankiff.com" title="Share">
          <TwitterIcon round={true} size={40}/>
        </TwitterShareButton>
      </Tooltip>
      <WhatsappShareButton url={currentPath}>
        <WhatsappIcon round={true} size={40}/>
      </WhatsappShareButton>
    </Space>
  )
}
