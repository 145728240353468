import React from "react"
import { DownOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import LogOutButton from "./LogOutButton"
import { Link } from "react-router-dom"
import { AvatarComponent } from "@components/Avatar"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const ProfilDropdown = () => {
  const { isLogged, userInfo } = useSelector((state) => state.auth)
  const { i18n } = useTranslation()
  const lang = i18n.language
  const dropDownItems = [
    {
      label: <Link to={`/${lang}/profil`}>Profil</Link>,
      key: "0"
    },
    {
      label: <LogOutButton />,
      key: "1"
    }
  ]
  return (
    <Dropdown
      menu={{
        items: isLogged ? dropDownItems : []
      }}
      trigger={["click"]}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <AvatarComponent size={"large"} picture={userInfo.avatar} />
          {/* {data ? `${userInfo.lastname} ${userInfo.firstname}` : ""} */}
          <DownOutlined style={{ fontSize: "12px" }} />
        </Space>
      </a>
    </Dropdown>
  )
}

export default ProfilDropdown
