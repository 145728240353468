import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import HomeCategoryView from "@components/HomeCategoryView"
import { useNavigate, useParams } from "react-router-dom"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { useSite } from "@hooks/useSite"
import { Col, Row } from "antd"
import { SkeletonPostCard } from "@components/SkeletonPostCard"
import Container from "@components/Container/Container"
import { get, post } from "@/axiosConfig"

export const Category = () => {
  const [category, setCategory] = useState(null)
  const [loading, setLoading] = useState(true)
  const { category_name } = useParams()
  const navigate = useNavigate()
  const { setPageTitle } = useSite();

  useEffect(async () => {
    setLoading(true)
    await get(`/category/${category_name}`)
      .then((response) => {
        if (response.data.status == 200) {
          setCategory(response.data.category[0])
          setPageTitle(response.data.category[0].name.charAt(0).toUpperCase() + response.data.category[0].name.slice(1))
          setLoading(false)
        } else if (response.data.status == 404) {
          navigate("/404")
        }
      })
      .catch(async (error) => {
        await post("/error/", {
          message: `${error.message}`
        })
      })
  }, [category_name])

  return (
    <MainLayout>
      {loading ?
        <Container>
          <Row gutter={[32, 32]}>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
          </Row>
        </Container>
        :
        <HomeCategoryView name={category ? category.name : ""} />
      }
    </MainLayout>
  )
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(Category)
