import React, { useEffect, useRef, useState } from "react"
import { Avatar, Button, Col, Form, Input, Row } from "antd"
// import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useUploadFile } from "../../../useFiles"
import { useTranslation } from "react-i18next"
// import { post } from "@/axiosConfig"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { AuthSection } from "@components/AuthSection"
import { Link } from "react-router-dom"
import Cookies from "js-cookie"
import { StepsComponent } from "./steps"
import { checkCookies } from "@/utils/functions"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons"

const ContributorStep4 = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    Cookies.set("step", 4, { expires: 7, path: "" })
    const requiredKeys = ["password", "email"]

    if (!checkCookies(requiredKeys)) {
      navigate("/contributor/step-3")
    }
  }, [navigate])

  const [selectedFile, setSelectedFile] = useState(!!Cookies.get("avatar"))
  const [picture, setPicture] = useState(Cookies.get("picture") || null)
  const [imageChanged, setImageChanged] = useState(false)
  const avatar = Cookies.get("avatar") || null
  const [loading, setLoading] = useState(false)
  const [bio, setBio] = useState(Cookies.get("bio") || "")

  const fileInputRef = useRef(null)
  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFile(file)
      setPicture(URL.createObjectURL(file))
      setImageChanged(true)
    }
  }

  const onFinish = (image) => {
    Cookies.set("avatar", image.key, { expires: 7, path: "" })
    Cookies.set("picture", picture, { expires: 7, path: "" })
    Cookies.set("bio", bio, { expires: 7, path: "" })
    navigate("/contributor/summary")
  }

  const uploadPicture = () => {
    if (imageChanged) {
      setLoading(true)
      useUploadFile(selectedFile, onFinish)
    } else {
      navigate("/contributor/summary")
    }
  }

  return (
    <MainLayout>
      <AuthSection>
        <StepsComponent currentStep={4} />
        <div className="auth-form">
          <Form
            name="normal_login"
            className="auth-form-body"
            layout="vertical"
            initialValues={{ remember: true }}
            style={{
              minWidth: 400
            }}
          >
            <h3>{t("title.choose_profile_picture")}</h3>
            <Row>
              <Col span={6}>
                <div>
                  {selectedFile && (
                    <div>
                      {avatar && !imageChanged ? (
                        <Avatar
                          shape="circle"
                          size="large"
                          src={`${process.env.REACT_APP_IMAGEKIT_ENDPOINT}/${avatar}`}
                        />
                      ) : (
                        <img
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                          src={picture}
                          alt="User's Selected Image"
                        />
                      )}
                    </div>
                  )}
                  <input
                    style={{ marginTop: "10px" }}
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={loading}
                  />
                </div>
              </Col>
            </Row>
            <Form.Item
              name="bio"
              label="Bio"
              className="auth-form-item"
              rules={[
                {
                  required: true,
                  message:
                    "Please put your the code your receive on your email!"
                }
              ]}
              style={{ marginTop: 10 }}
            >
              <Input.TextArea
                placeholder="Enter your Bio"
                value={bio}
                defaultValue={bio}
                type="text"
                onChange={(e) => setBio(e.target.value)}
              />
            </Form.Item>
            <Row gutter={12} justify="space-between">
              <Col>
                <Button
                  style={{ marginTop: "10px" }}
                  type="primary"
                  className="auth-form-button"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate("/contributor/step-3")}
                ></Button>
              </Col>
              <Col>
                {/* pictureFromCookies */}
                <Button
                  type="primary"
                  style={{ marginTop: "10px" }}
                  htmlType="submit"
                  className="auth-form-button"
                  onClick={() => uploadPicture()}
                  icon={<ArrowRightOutlined />}
                  disabled={selectedFile === null || loading}
                  loading={loading}
                ></Button>
              </Col>
            </Row>
          </Form>
          <div className="auth-form-footer">
            <Button type="text" className="auth-form-forgot">
              <Link to="/login">{t("login")}</Link>
            </Button>
          </div>
        </div>
      </AuthSection>
    </MainLayout>
  )
}

export { ContributorStep4 }
