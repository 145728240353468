import { Space } from "antd"
import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookShareCount,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
} from "react-share"
export default function Socials() {
  const currentPath = window.location.href
  return (
    <Space style={{ padding: "16px 0px" }}>
      <FacebookShareButton url={currentPath} hashtag="" title="">
        <FacebookIcon round={true} size={40}/>
      </FacebookShareButton>
      <FacebookShareCount url={currentPath}/>
      <TwitterShareButton url={currentPath} hashtag="urbankiff.com" title="">
        <XIcon round={true} size={40}/>
      </TwitterShareButton>
      <WhatsappShareButton url={currentPath}>
        <WhatsappIcon round={true} size={40}/>
      </WhatsappShareButton>
    </Space>
  )
}
