import { setIsLogged, setUserInfo } from "@/slices/authSlice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useGetUser = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const token = localStorage.getItem("login_token")
  const { isLogged } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      if (!isLogged) {
        try {
          const response = await fetch(BASE_URL + "/me", {
            method: "GET",
            headers: {
              Authorization: `Token ${token ? JSON.parse(token) : null}`
            }
          })
          if (!response.ok) throw new Error(response.statusText)
          const json = await response.json()
          setLoading(false)
          setData(json)
          dispatch(setUserInfo(json))
          dispatch(setIsLogged(true))
          setError(null)
          return json
        } catch (error) {
          setError(`${error}. Could not fetch data`)
          setLoading(false)
        }
      }
    }
    get()
  }, [])

  return { data, loading, error }
}

export default useGetUser
