import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
// import { get } from "@/axiosConfig"
import axios from "axios"

const LogOutButton = () => {
  const [isLogged, setIsLogged] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  useEffect(() => {
    const token = localStorage.getItem("login_token")
    axios
      .get("/me", { headers: { Authorization: `Token ${token}` } })
      .then((response) => {
        if (response.data) {
          setIsLogged(true)
        }
      })
      .catch(() => {
        localStorage.removeItem("login_token")
      })
  }, [isLogged])
  const signOut = () => {
    localStorage.removeItem("login_token")
    navigate("/login")
  }
  return (
    <Button
      onClick={() => signOut()}
      type={"primary"}
      htmlType="submit"
      className="login-button"
      danger
    >
      {isLogged ? t("sign_out") : t("login")}
    </Button>
  )
}

export default LogOutButton
