import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import ReportModal from "./ReportModal"
import { useTranslation } from "react-i18next"
import useCheckLogin from "@/hooks/useCheckLogin"

const Report = ({ postId, commentId }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { data } = useCheckLogin()
  return (
    <div>
      {data && postId && (
        <Button danger onClick={() => setOpen(!open)}>
          {t("buttons.report")}
        </Button>
      )}
      {data && commentId && (
        <p
          style={{ fontSize: "10px", cursor: "pointer", color: "red" }}
          onClick={() => setOpen(!open)}
        >
          {t("buttons.report")}
        </p>
      )}
      <ReportModal
        postId={postId}
        open={open}
        commentId={commentId}
        setOpen={setOpen}
      />
    </div>
  )
}

export default Report

Report.propTypes = {
  postId: PropTypes.number,
  commentId: PropTypes.number
}
