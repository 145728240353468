import React, { useEffect, useState } from "react"
import { MainLayout } from "../Layouts/MainLayout"
import Container from "@/components/Container/Container"
import { Button, Col, Row, Space, Modal } from "antd"
import Title from "antd/lib/typography/Title"
import useGetUser from "@/hooks/useGetUser"
import { Typography } from "antd"
import "./profile.sass"
import { toast } from "react-toastify"
import axios from "axios"
import EditModal from "./EditModal"
import { useNavigate } from "react-router-dom"
import { EditOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import EditImageModal from "./EditImageModal"
import ChangePaswword from "./ChangePassword"
import { AvatarComponent } from "@components/Avatar"
import EditPhoneNumberModal from "./EditPhoneNumber"
import { useTranslation } from "react-i18next"
import UsernameModal from "./UsernameModal"

const Profile = () => {
  const { data } = useGetUser()
  const { Text } = Typography
  const { confirm } = Modal
  const token = localStorage.getItem("login_token")
  const [open, setOpen] = useState(false)
  const [imageopen, setImageopen] = useState(false)
  const [passwordopen, setPasswordopen] = useState(false)
  const [phoneModalOpen, setPhoneModalOpen] = useState(false)
  const [usernameOpen, setUsernameOpen] = useState(false)
  const [user, setUser] = useState(data ? data : {})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  useEffect(() => {
    if (data) {
      setUser(data)
    }
  }, [data])
  const showModal = () => {
    setOpen(true)
  }

  const handleOk = async () => {
    setLoading(true)
    axios
      .put(`/user/${user.id}`, user, {
        headers: { authorization: `Token ${token}` }
      })
      .then(() => {
        window.location.reload(false)
      })
    setOpen(false)
    setLoading(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }
  const onDeleteProfil = () => {
    axios
      .delete(`/user/${data.id}`, {
        headers: { authorization: `Token ${token}` }
      })
      .then(() => {
        toast(t("messages.account_deleted"))
        localStorage.removeItem("login_token")
        navigate(`/${lang}/login`)
      })
  }
  const onHandleChange = (e) => {
    const { name, value } = e.target
    setUser((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const showConfirm = () => {
    confirm({
      title: t("messages.confirm_delete_modal_title"),
      icon: <ExclamationCircleFilled />,
      content: t("messages.confirm_delete_modal_content"),
      onOk() {
        onDeleteProfil()
      },
      onCancel() {
        console.log("Cancel")
      }
    })
  }

  return (
    <MainLayout>
      <Container>
        <Row>
          <Title level={2}>{t("text.your_profile")}</Title>
        </Row>
        <Row className="profile-row">
          <Col span={6}>
            <AvatarComponent size={128} picture={data.avatar} />
            <span
              className="profil-edit-icon"
              onClick={() => setImageopen(!imageopen)}
            >
              <EditOutlined />
            </span>
          </Col>
          <Col span={12}>
            <Space direction="vertical">
              <Text level={4}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  {t("text.username")}
                </span>
                {data.username ? data.username : t("text.not_defined")}
                {!data.username && (
                  <Button
                    type="default"
                    size="small"
                    style={{ marginLeft: "10px" }}
                    onClick={() => setUsernameOpen(true)}
                  >
                    {t("buttons.add")}
                  </Button>
                )}
              </Text>
              <Text level={4}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  {t("text.full_name")}
                </span>
                {data.lastname} {data.firstname}
              </Text>
              <Text level={5}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  Email:
                </span>
                {data.email}
              </Text>
              <Text level={5}>
                <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                  {t("input.phone_number")}
                </span>
                {data.contact}
                <Button
                  danger
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setPhoneModalOpen(true)}
                >
                  {t("buttons.edit")}
                </Button>
              </Text>
              <Button onClick={() => setPasswordopen(true)} danger>
                {t("buttons.change_password")}
              </Button>
            </Space>
          </Col>
          <Col span={6}>
            <Space direction="vertical">
              <Button type="primary" onClick={showModal}>
                {t("buttons.edit")}
              </Button>
              <Button type="primary" danger onClick={showConfirm}>
                {t("buttons.delete")}
              </Button>
            </Space>
          </Col>
        </Row>
        <EditModal
          open={open}
          handleCancel={handleCancel}
          handleOk={handleOk}
          user={user}
          onHandleChange={onHandleChange}
          loading={loading}
        />
        <EditImageModal
          open={imageopen}
          setOpen={setImageopen}
          userId={data.id}
        />
        <ChangePaswword
          open={passwordopen}
          setOpen={setPasswordopen}
          userId={data.id}
          email={data.email}
        />
        <EditPhoneNumberModal
          open={phoneModalOpen}
          setOpen={setPhoneModalOpen}
        />
        <UsernameModal open={usernameOpen} setOpen={setUsernameOpen} data={data} />
      </Container>
    </MainLayout>
  )
}

export default Profile
