import { useTranslation } from "react-i18next";
const useAuth = () => {
  const { t } = useTranslation()

  const validateMessages = {
    required: '${label} '+ t('input.is_required'),
    types: {
      email: '${label} '+ t('input.is_not_valid'),
      number: '${label} '+ t('input.is_not_valid'),
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
  return {
    validateMessages
  }
}

export { useAuth }