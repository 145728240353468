import axios from 'axios';
import { i18n } from './i18n';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 50000,
});

const get = (url, params) => {
  params = { ...params, language: i18n.language };
  return instance.get(url, { params });
};

const post = (url, data) => {
  return instance.post(url, data);
};

const put = (url, data) => {
  return instance.put(url, data);
};

const del = (url, data) => {
  return instance.delete(url, data);
};

export { get, post, put, del };