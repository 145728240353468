import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  title: '',
  description: '',
  image: ''
}

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setPageHeader: (state, action) => {
      state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setPageHeader } = headerSlice.actions

export default headerSlice.reducer