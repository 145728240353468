import React, { useEffect, useState } from "react"
import { Col, Row, Typography, Space, Button, Popover } from "antd"
import {
  EyeOutlined,
  CommentOutlined,
  ShareAltOutlined,
  PlayCircleTwoTone
} from "@ant-design/icons"
import Container from "@components/Container/Container"
import { useNavigate, useParams } from "react-router-dom"
import Socials from "@components/Socials"
import { SideBar } from "@containers/Sidebar"
import { useSelector } from "react-redux"
const { Title, Text } = Typography
import { Date } from "@/components/Date"
import { setPageHeader } from "@slices/headerSlice"
import { useDispatch } from "react-redux"
import axios from "axios"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { Comments } from "../Comments/Comments"
import Likes from "@/components/Likes"
import { useSite } from "@hooks/useSite"
import ReactPlayer from "react-player"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { useTranslation } from "react-i18next"
import "./single-post.sass"
import { SinglePostSkeleton } from "@components/SinglePostSkeleton"
import { SinglePostImage } from "@components/SinglePostImage"
import Report from "@/components/Report"

const SinglePost = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { setPageTitle } = useSite()
  const [post, setPost] = useState(null)
  const [commentsCount, setCommentsCount] = useState(0)
  const { post_title } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { posts } = useSelector((state) => state.homePosts)
  const isContentNotEmpty =
    post?.content.trim().replace(/<[^>]*>?/gm, "").length > 0
  const IncrementViews = () => {
    axios
      .post("/post/incrementviews", post)
      .then(() => {})
      .catch((error) => {
        axios.post("/error/", {
          message: `${error.message}`
        })
      })
  }
  const fetchPost = async (slug) => {
    setLoading(true)
    axios
      .get(`/post/fetchpostdata/${slug}`)
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          navigate("/404")
          return
        }
        setPost(response.data[0])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        axios.post("/error/", {
          message: `${error.message}`
        })
      })
  }
  const getPost = async (slug) => {
    let exists = posts.filter((item) => item.slug === slug)[0]
    if (!exists) {
      exists = await fetchPost(slug)
      return
    }
    setPost(exists)
  }

  useEffect(() => {
    const page = document.getElementById("page")
    if (page) {
      page.scrollTo({ top: 0 })
    }
    setPost(null)
    getPost(post_title)
  }, [post_title])

  useEffect(() => {
    if (post && post.id) {
      IncrementViews()
      dispatch(
        setPageHeader({
          title: post.title,
          description: post.intro,
          image: post.image
        })
      )
      setPageTitle(post.title)
      console.log(post)
    }
  }, [post])

  return (
    <MainLayout key={post_title}>
      {loading && <SinglePostSkeleton />}
      {!loading && post && (
        <>
          <Container>
            <Title level={1} style={{ margin: 0 }}>
              {post.title}
            </Title>
            <Text type="secondary">
              Publié le <Date time={post.created_at} />
            </Text>
          </Container>
          <SinglePostImage image={post.image} />
          <Container>
            <Row>
              <Col md={18}>
                {isContentNotEmpty && (
                  <div
                    style={{ marginBottom: 60 }}
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  ></div>
                )}
                {post.audio ? (
                  <div style={{ maxWidth: 600, marginBottom: 10 }}>
                    <AudioPlayer
                      header={t("messages.listen_audio")}
                      hasDefaultKeyBindings="false"
                      src={post.audio}
                      customIcons={{
                        play: <PlayCircleTwoTone color="primary" />
                      }}
                    />
                  </div>
                ) : null}

                {post.video ? (
                  <div style={{ marginBottom: 10, width: "100%" }}>
                    <ReactPlayer
                      width={"100%"}
                      height={"auto"}
                      style={{ aspectRatio: "16/9" }}
                      url={post.video}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 0px"
                  }}
                >
                  <Space>
                    <Likes postId={post.id} />
                    <Space>
                      <Button
                        shape="circle"
                        size="large"
                        icon={<EyeOutlined />}
                      />
                      <span>{post.views} view(s)</span>
                    </Space>
                    <Space>
                      <Button
                        shape="circle"
                        size="large"
                        icon={<CommentOutlined />}
                      />
                      <span>{commentsCount} comment(s)</span>
                    </Space>
                  </Space>
                  <Space>
                    <Report postId={post.id} />
                    <Popover
                      placement="bottom"
                      content={Socials}
                      title="Share"
                      trigger="click"
                    >
                      <Button
                        shape="circle"
                        size="large"
                        icon={<ShareAltOutlined />}
                      />
                    </Popover>
                    <span> Share</span>
                  </Space>
                </div>

                <Comments
                  postId={post.id}
                  setCommentsCount={setCommentsCount}
                />
              </Col>
              <Col md={6}>
                <SideBar />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </MainLayout>
  )
}

export { SinglePost }
