import React from "react";
// import  bannerImage from "@assets/images/banner-1.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
export default function BannerImage() {
  const settings = {
    dots: false,
    fade: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    className: 'banner'
  };

  return (
    <div className="">
      <div className="banners">
        <Slider {...settings}>
          <div>  <img src={ "https://ik.imagekit.io/ubnews/banners/tr:w-1200,h-250/bannerUDIHIHIHQSHQ87QSIH8QS8YQIBJBNJBNQS"} /></div>
          <div>  <img src={ "https://ik.imagekit.io/ubnews/banners/tr:w-1200,h-250/bannerHDLKKLJQKLJ83847N89SN8B98DN"} /></div>
          <div>  <img src={ "https://ik.imagekit.io/ubnews/banners/tr:w-1200,h-250/banner152R46168768267GVHQVS"} /></div>
          <div>  <img src={ "https://ik.imagekit.io/ubnews/banners/tr:w-1200,h-250/banner678798798SH98H8SH8HQS8"} /></div>
          <div>  <img src={ "https://ik.imagekit.io/ubnews/banners/tr:w-1200,h-250/banner56372737GQS7TFVT7STV7VS7VS7"} /></div>
        </Slider>
      </div>
    </div>
    
  );
}
